import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-ext_links" }
const _hoisted_2 = { class: "c-ext_links__item bunka" }
const _hoisted_3 = {
  href: "https://www.bunka.go.jp/",
  target: "_blank",
  rel: "noopener noreferrer"
}
const _hoisted_4 = { class: "c-ext_links__item epad" }
const _hoisted_5 = {
  href: "https://db.epad.jp/",
  target: "_blank",
  rel: "noopener noreferrer"
}
const _hoisted_6 = { class: "c-ext_links__item enpaku" }
const _hoisted_7 = {
  href: "https://www.waseda.jp/enpaku/",
  target: "_blank",
  rel: "noopener noreferrer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("ul", _hoisted_1, [
    _createVNode("li", _hoisted_2, [
      _createVNode("a", _hoisted_3, [
        _createVNode("img", {
          class: "c-ext_links__img",
          src: _ctx.epksrc('/assets/img/logo_bunka.svg'),
          alt: "文化庁"
        }, null, 8, ["src"])
      ])
    ]),
    _createVNode("li", _hoisted_4, [
      _createVNode("a", _hoisted_5, [
        _createVNode("img", {
          class: "c-ext_links__img",
          src: _ctx.epksrc('/assets/img/logo_epad.svg'),
          alt: "EPAD（緊急舞台芸術アーカイブ+デジタルシアター化支援事業）"
        }, null, 8, ["src"])
      ])
    ]),
    _createVNode("li", _hoisted_6, [
      _createVNode("a", _hoisted_7, [
        _createVNode("img", {
          class: "c-ext_links__img",
          src: _ctx.epksrc('/assets/img/logo_enpaku.svg'),
          alt: "enpaku 早稲田大学演劇博物館"
        }, null, 8, ["src"])
      ])
    ])
  ]))
}