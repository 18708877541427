import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "js-keywords",
  class: "c-keywords"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (0 < _ctx.menuKeywords.length)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.menuKeywords, (keyword) => {
          return (_openBlock(), _createBlock("span", {
            key: keyword.id
          }, [
            _createVNode("a", {
              class: "js-text-link",
              href: '#' + keyword.id,
              "data-txt": keyword.word
            }, [
              _createVNode("span", null, _toDisplayString(keyword.word), 1)
            ], 8, ["href", "data-txt"])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}