
import { defineComponent } from "vue";

export default defineComponent({
  name: "Pagination",
  props: {
    currentPage: Number,
    lastPage: Number,
    pageset: Array,
    handleChangePage: Function,
  },
});
