import { computed, reactive } from 'vue';

const ja_JP = require('@/config/lang/ja_JP.js');
const en_US = require('@/config/lang/en_US.js');

const urlLangEn = window.location.pathname.match(/^\/en($|\/)/);

interface PageState {
    pageKey: string;
    language: SupportedLanguages;
    current: any;
    loader: boolean;
    error: GlobalError | undefined;
}
type GlobalError = {
    code: number;
    message: string;
}

type SupportedLanguages = 'ja_JP' | 'en_US';

const state = reactive<PageState>({
    pageKey: 'home',
    language: urlLangEn ? 'en_US' : 'ja_JP',
    current: urlLangEn ? en_US : ja_JP,
    loader: false,
    error: undefined,
});

export const pageKey = computed(() => state.pageKey);
export const language = computed(() => state.language);
export const dictionary = computed(() => state.current);
export const loader = computed(() => state.loader);

export const globalError = computed(() => state.error);

export const setPageKey = (pageKey: string) => (state.pageKey = pageKey);
export const setGlobalLoader = (loader: boolean) => (state.loader = loader);
export const setGlobalError = (code: number, message: string) => (state.error = { code, message });
export const setLanguage = (lang: SupportedLanguages): Promise<void> => new Promise<void>(
    resolve => {
        if (state.language == lang) {
            resolve();
        } else {
            const setEn = lang === 'en_US';
            const urlLangEn = window.location.pathname.match(/^\/en($|\/)/);
            if (setEn && !urlLangEn) {
                const href = '/en' + window.location.pathname;
                window.location.href = href;
            } else if (!setEn && urlLangEn) {
                const href = window.location.pathname.toString().replace(/^\/en/, '') || '/';
                window.location.href = href;
            } else {
                state.language = lang;
                state.current = lang === 'en_US' ? en_US : ja_JP;
                resolve();
            }
        }
    }
);
