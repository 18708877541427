import { OmekaReferenceValue, OmekaTimestamp, Playtext } from '@/enpaku';
import { site } from '@/util/config';
import axios from 'axios';
import { FetchPlaytextRecordset } from './interface';

const endpoint = site.apiEndpoints.playtexts;

interface OmekaPlaytextRecord {
    '@context': string;
    '@id': string;
    '@type': 'o:Playtext';
    'o:id': number;
    'o:identifier': string;
    'o:author_japanese': string;
    'o:author_japanese_kana': string;
    'o:author_english': string;
    'o:title_japanese': string;
    'o:title_japanese_kana': string;
    'o:title_english': string;
    'o:disallow_free_publication_on_jdta': boolean;
    'o:free_publication_on_jdta': string;
    'o:number_of_performers_japanese': string;
    'o:number_of_performers_english': string;
    'o:number_of_performers_sort': string;
    'o:year_issued': string;
    'o:created': OmekaTimestamp;
    'o:modified': OmekaTimestamp;
    'o:owner': OmekaReferenceValue;
}

const fetchPlaytextRecordset: FetchPlaytextRecordset = async ({
    condition = {
        search: '',
    },
    lang = 'ja',
    page = 1,
    perPage = 25,
    sortBy,
    sortOrder,
}) => {
    const requestPayload = {
        lang,
        page,
        per_page: perPage,
        sort_by: sortBy,
        sort_order: sortOrder,
        search: condition.search,
    };

    const axiosResponse = await axios({
        method: 'get',
        url: endpoint,
        params: requestPayload,
        responseType: 'json',
    });

    if (axiosResponse.status !== 200) {
        throw new Error(
            'サーバーとの接続に失敗しました ' +
                axiosResponse.status.toString() +
                ': ' +
                axiosResponse.statusText
        );
    }

    const recordset = (axiosResponse.data as OmekaPlaytextRecord[]).map((r) =>
        regularize(r, lang)
    );

    const totalRecords =
        'jdta-total-results' in axiosResponse.headers
            ? Number(axiosResponse.headers['jdta-total-results'])
            : recordset.length;

    return { totalRecords, recordset };
};

const regularize = (r: OmekaPlaytextRecord, lang: string): Playtext => {
    const inJapanese = lang === 'ja';
    return {
        id: r['o:id'],
        identifier: r['o:identifier'],
        allowFreePublicationOnJdta: !r['o:disallow_free_publication_on_jdta'],
        title: inJapanese ? r['o:title_japanese'] : r['o:title_english'],
        author: inJapanese ? r['o:author_japanese'] : r['o:author_english'],
        yearIssued: r['o:year_issued'],
        numberOfPerformers: inJapanese
            ? r['o:number_of_performers_japanese']
            : r['o:number_of_performers_english'],
    };
};

export default fetchPlaytextRecordset;
