export const LoadingState = {
    /**
     * 0: not loading
     */
    notLoading: 0,
    /**
     * 1: searching
     */
    searching: 1,
    /**
     * 2: additional searching
     */
    additionalSearching: 2,
    /**
     * 3: paging or sorting
     */
    pagingOrSorting: 3,
} as const;

export type LoadingStateType = typeof LoadingState[keyof typeof LoadingState];
