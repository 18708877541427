
// .js-videoWrapper
//  ├─ ...
//  ├─ .js-playVideo (@click)
//  ├─ .js-iframe
//  └─ ...

type YTPlayer = any;
let scriptLoading: null | Promise<void> = null;

/**
 * @returns {Promise<void>}
 */
const loadScript = () => scriptLoading
    ? scriptLoading
    : (scriptLoading = new Promise<void>(resolve => {
        const ytTag = document.createElement('script');
        ytTag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.querySelector('script');
        if (firstScriptTag && firstScriptTag.parentNode) {
            firstScriptTag.parentNode.insertBefore(ytTag, firstScriptTag);
            (window as any).onYouTubeIframeAPIReady = () => resolve();
        } else {
            throw new Error('<script> tag is not found')
        }
    }));

const loadingPlayers: Record<string, Promise<YTPlayer>> = {};

/**
 * @returns {Promise<{player: YTPlayer, frameId: string, videoId: string}>}
 */
const getPlayer = async (frameId: string, videoUrl: string): Promise<YTPlayer> => {
    let videoId: string = '';
    if (videoUrl.match(/^https?:\/\/youtu\.be\/([-._0-9a-zA-Z]+)$/)) {
        videoId = RegExp.$1;
    }
    if (!videoId) {
        throw new Error('Specified invalid youtube url');
    }
    await loadScript();
    if ((frameId in loadingPlayers)) {
        const { player, videoId: curVideoId } = await loadingPlayers[frameId];
        if (curVideoId !== videoId) {
            throw new Error('The iframe is already mounted as youtube player');
        }
        return player
    } else {
        loadingPlayers[frameId] = loadPlayer(frameId, videoId);
        const { player } = await loadingPlayers[frameId];
        return player;
    }
}

const loadPlayer = (frameId: string, videoId: string) => new Promise<
    { player: YTPlayer; frameId: string; videoId: string }
>(resolve => {
    new (window as any).YT.Player(frameId, {
        height: '390',
        width: '640',
        videoId: videoId,
        playerVars: {
            rel: 0,
        },
        origin: location.protocol + '//' + location.hostname + "/",
        events: {
            'onReady': (event: any) => {
                resolve({
                    player: event.target,
                    frameId,
                    videoId
                });
            },
            'onStateChange': (event: any) => {
                if (event.data == (window as any).YT.PlayerState.ENDED || event.data == (window as any).YT.PlayerState.PAUSED) {
                    event.target.h.parentNode.classList.remove('is-playing');
                }
                if (event.data == (window as any).YT.PlayerState.BUFFERING || event.data == (window as any).YT.PlayerState.PLAYING) {
                    event.target.h.parentNode.classList.add('is-playing');
                }
            }
        }
    });
})

export default async function playVideo(playVideoTriggerElement: HTMLElement, videoUrl: string) {
    const videoWrapper = playVideoTriggerElement.parentNode as HTMLElement | null; //.js-videoWrapper
    const ytFrame = playVideoTriggerElement.nextElementSibling as HTMLElement | null; //.js-iframe
    if (!videoWrapper || !ytFrame) return;

    let ytFrameId: string | null = ytFrame.getAttribute('id');
    if (!ytFrameId) {
        const uniqFrameId = Math.random().toString(32).substring(2) + '-' + new Date().getTime().toString();
        ytFrame.setAttribute('id', uniqFrameId);
        ytFrameId = uniqFrameId;
    }
    videoWrapper.classList.add('is-playing');

    const player = await getPlayer(ytFrameId, videoUrl);
    player.playVideo();
}
