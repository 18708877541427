
import { computed, defineComponent, PropType } from "vue";
import { dictionary, language } from "@/compositions/pageComposition";
import { TheatricalResource, TheatricalResourceItem } from "@/enpaku";

export default defineComponent({
  name: "TheatricalItemOutline",
  props: {
    open: Boolean,
    record: Object as PropType<TheatricalResource|TheatricalResourceItem>,
  },
  setup(props) {

    const keywords = computed(()=>{
      if(!props.record) return '';
      const values = props.record.TheatricalResource$epkapi__keywords;
      const separator = language.value === 'en_US' ? ', ' : ' ';
      return values.join(separator)
    });

    return {
      lang: dictionary ,
      keywords
    };
  },
});
