var selectors = require('./selectors');
var lottie = require('lottie-web');

function splash() {
  const bodyLikeElement = document.querySelector(selectors.body);
  const splashContainer = document.querySelector(selectors.splash);
  const menu = document.querySelector(selectors.splashWidthId);
  if(!splashContainer) return Promise.resolve(false);

  return new Promise(resolve => {
    var path1, path2, logoContainer;
    var windowAspectRatio = window.innerWidth / window.innerHeight;
    var preserveAspectRatio = 'xMidYMid slice';
    if ( window.innerWidth > 750 ) {
      path1 = '/assets/json/splash-1.json';
      path2 = '/assets/json/splash-2.json';
      logoContainer = document.querySelector(selectors.splashLogoLgId);
    } else {
      path1 = '/assets/json/splash-sp1.json';
      path2 = '/assets/json/splash-sp2.json';
      logoContainer = document.querySelector(selectors.splashLogoSmId);
    }
    var params1 = {
        container: splashContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: path1,
        rendererSettings: { progressiveLoad:false, preserveAspectRatio: preserveAspectRatio }
    };
    var params2 = {
        container: logoContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: path2,
    };
    var anim1, anim2;
    anim1 = lottie.loadAnimation(params1);
    anim1.addEventListener('enterFrame', function(e) {
      if (e.currentTime > 196) {
        menu.classList.add('is-visible');
        anim1.removeEventListener('enterFrame');
      }
    });
    anim1.addEventListener('complete', function() {
      bodyLikeElement.classList.add('anim1-end');

      anim2 = lottie.loadAnimation(params2);
      anim2.addEventListener('complete', function() {
        setTimeout(function() {
          bodyLikeElement.classList.add('anim2-end');
          setTimeout(function() {
            resolve(true);
          }, 1500);
        }, 500);
      });
    });
  });
}
splash.skip = function() {
  const bodyLikeElement = document.querySelector(selectors.body);
  const splashContainer = document.querySelector(selectors.splash);
  if(!splashContainer) return Promise.resolve(false);

  const menu = document.querySelector(selectors.splashWidthId);
  return new Promise(resolve => {
    bodyLikeElement.classList.add('anim1-end');
    bodyLikeElement.classList.add('anim2-end');
    menu.classList.add('is-visible');
    resolve(true);
  });
}

module.exports = splash;
