import { reactive, ref } from 'vue';
import { MenuPostAdaptItem } from '@/enpaku';
import { language } from './pageComposition';
import * as fontplus from '@/fontplus';
import { fetchMenuPosts } from '@/api/fetchMenuPosts';

const displayLoadedMax = 12;
const displaySearchMax = 12;
const prepared = 30;

interface MenuThumbnailsState {
    displayMode: 'loaded' | 'searchResult';
    loadedPosts: MenuPostAdaptItem[];
    loadedDisplay: MenuPostAdaptItem[];
    nextIndex: number | undefined;
    nextItem: MenuPostAdaptItem | undefined;

    searchKeyword: string;
    searchResults: MenuPostAdaptItem[];
}

const state = reactive<MenuThumbnailsState>({
    displayMode: 'loaded',
    loadedPosts: [],
    loadedDisplay: [],
    nextIndex: undefined,
    nextItem: undefined,
    searchKeyword: '',
    searchResults: [],
});

// selectors
export const getNextIndexAndItem = () => ({ item: state.nextItem, index: state.nextIndex });
export const displayMode = () => state.displayMode;
export const loadedDisplay = () => state.loadedDisplay;
export const searchResultsDisplay = () => state.searchResults;

// mutations
export const setDisplayMode = (displayMode: 'loaded' | 'searchResult') => (state.displayMode = displayMode)

type Unregisterer = () => void;
const fontplusUnregisterRefs = [ref<Unregisterer>(), ref<Unregisterer>()];
let fontplusUnregisterCounter = 0;
const fetchMenuPostsWithHandleFonts = async (params: any) => {
    const response = await fetchMenuPosts(params);
    const i = fontplusUnregisterCounter % fontplusUnregisterRefs.length;
    const unregisterPrev = fontplusUnregisterRefs[i].value;
    unregisterPrev && unregisterPrev();
    fontplusUnregisterRefs[++fontplusUnregisterCounter % fontplusUnregisterRefs.length].value =
        fontplus.registerText(JSON.stringify(response));
    await fontplus.loadFontplus('menuThumbnailsComposition');

    return response;
}

export const loadThumbnails = async (count: number = 30) => {
    const lang = language.value === 'en_US' ? 'en' : 'ja';
    const limit = count + prepared // at first
    const { recordset: posts } = await fetchMenuPostsWithHandleFonts({ lang, limit });

    const initialItems = posts.splice(0, Math.min(displayLoadedMax, posts.length));
    state.loadedPosts = posts;
    state.loadedDisplay = initialItems;
};

export const prepareNextItem = async () => {
    if (state.loadedPosts.length < prepared) {
        const lang = language.value === 'en_US' ? 'en' : 'ja';
        // console.log('least ' + state.loadedPosts.length.toString());
        // console.log('more ' + prepared.toString());
        const { recordset } = await fetchMenuPostsWithHandleFonts({
            lang,
            limit: prepared
        });
        state.loadedPosts = state.loadedPosts.concat(recordset);
    }
    state.nextItem = state.loadedPosts.shift();
    state.nextIndex = Math.floor(Math.random() * state.loadedDisplay.length);
};
export const searchThumbnails = async (searchKeyword = '') => {
    const lang = language.value === 'en_US' ? 'en' : 'ja';
    const limit = displaySearchMax;
    const { recordset } = await fetchMenuPostsWithHandleFonts({
        lang,
        limit,
        condition: { searchKeyword },
    });
    state.searchKeyword = searchKeyword;
    state.searchResults = recordset;
};
export const swapThumbnail = () => {
    if (state.nextItem && state.nextIndex !== undefined) {
        state.loadedDisplay.splice(state.nextIndex, 1, state.nextItem);
        state.nextItem = undefined;
        state.nextIndex = undefined;
    }
};

// 画像読み込みをまつ
// const waitImageLoaded = (src: string) => new Promise<void>(
//     resolve => {
//         const img = new Image;
//         img.src = src;
//         img.onload = () => resolve();
//     }
// );

export default state;
