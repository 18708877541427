module.exports = {
    "site": {
        "description": 'Japan Digital Theatre Archives (JDTA) は、緊急舞台芸術アーカイブ＋デジタルシアター化支援事業（EPAD）の一環として早稲田大学演劇博物館が開設した、現代演劇・舞踊・伝統芸能の三分野にわたる舞台公演映像の情報検索特設サイトです。'
    },
    "about": {
        "credits": {
            "company": {
                "enpaku": "早稲田大学演劇博物館",
                "ndc": "株式会社日本デザインセンター",
                "nice": "オフィスナイス",
                "vectorDesign": "株式会社ベクターデザイン",
            },
            "staff": {
                "team": {
                    "enpaku": "岡室美奈子、野原佳名子、松谷はるな、鈴木淳子、中西智範、佐久間慧、沢美也子、玉木暢子、矢澤大吾／［協力］森千晴、木造希、久保木柚有、中村隆一、和田悟志、岩永祐一（LeMerlin）",
                    "ndc": "齋藤裕行、富田卓、細見裕太",
                    "nice": "清水恒平、三浦早織、渡辺祐一",
                    "vectorDesign": "梅澤幻、間野健介、井上純一、加納正行、木原進／［協力］藤本貴子",
                },
                "team2": {
                    "enpaku": "岡室美奈子、野原佳名子、岡原明子、小松めぐみ、富重聡子、小西晋太郎、山田菜美",
                },
                "team3": {
                    "enpaku": "岡室美奈子、和田修、野原佳名子、岡原明子、中西智範、渡邉真澄、河端奈歩、清水真希子、田村優実、馬渕敬子、矢内有紗",
                    "cooperate": "［協力］尾崎瑠衣、堀美佳、早坂彩、小西晋太郎、岸本佳子、Samuel Annesley（サミュエル　アネスリー）",
                },
                "specialThanks": {
                    "_01": "杉本重雄（筑波大学名誉教授）",
                    "_02": "中村覚（東京大学史料編纂所助教）",
                    "_03": "福井健策、田島佑規（骨董通り法律事務所）",
                    "enpaku": "児玉竜一、後藤隆基、原田真澄、藤谷桂子、柴田康太郎、長谷川理絵、三好珠貴、三枝英子",
                    "_04": "尾崎瑠衣、堀美佳、早坂彩、小西晋太郎、岸本佳子、Samuel Annesley（サミュエル　アネスリー）",
                },
            },
            "title": {
                "specialThanks": "当サイト開設にあたりご協力いただいた方々（敬称略）",
                "team": "Japan Digital Theatre Archives 制作チーム（2020年度）",
                "team2": "2022年度JDTAスタッフ",
                "team3": "2023年度JDTAスタッフ",
            },
        },
        "par01": "Japan Digital Theatre Archives (JDTA) は、<a class=\"c-text__link c-text__link--external\" href=\"https://db.epad.jp/\" data-txt=\"緊急舞台芸術アーカイブ＋デジタルシアター化支援事業（EPAD）\" target=\"_blank\"><span>緊急舞台芸術アーカイブ＋デジタルシアター化支援事業（EPAD）</span></a>の一環として早稲田大学演劇博物館（以下、エンパク）が開設した、現代演劇・舞踊・伝統芸能の三分野にわたる舞台公演映像の情報検索特設サイトです。",
        "par02": "2020年、新型コロナウィルスの感染拡大に伴い舞台芸術界では多くの公演が延期や中止を余儀なくされ、また、人数制限等により観客数が激減するなど、大きな打撃を受けました。EPADは、<a class=\"c-text__link c-text__link--external\" href=\"https://www.terrada.co.jp/ja/\" data-txt=\"寺田倉庫株式会社\" target=\"_blank\"><span>寺田倉庫株式会社</span></a>と<a class=\"c-text__link c-text__link--external\" href=\"https://www.jpasn.net\" data-txt=\"緊急事態舞台芸術ネットワーク\" target=\"_blank\"><span>緊急事態舞台芸術ネットワーク</span></a>がタッグを組み、コロナ禍により困難に陥った舞台芸術を支援し収益力強化に寄与することを目的として、令和2年度<a class=\"c-text__link c-text__link--external\" href=\"https://www.bunka.go.jp/shinsei_boshu/kobo/92491501.html\" data-txt=\"文化庁文化芸術収益力強化事業\" target=\"_blank\"><span>文化庁文化芸術収益力強化事業</span></a>に採択された委託事業です。EPADの特色は、演劇・舞踊・伝統芸能の三分野にわたる舞台公演の新規収録を支援するだけではなく、既存の舞台公演映像や戯曲を収集したこと、そして権利処理に踏み込むことで配信可能化を目指したことにあります。",
        "par03": "エンパクは2014年度から4年間にわたり、文化庁美術館・歴史博物館重点分野推進支援事業として<a class=\"c-text__link c-text__link--external\" href=\"https://www.waseda.jp/prj-stage-film/\" data-txt=\"「舞台芸術・芸能関係映像のデジタル保存・活用に関する調査研究事業」\" target=\"_blank\"><span>「舞台芸術・芸能関係映像のデジタル保存・活用に関する調査研究事業」</span></a>に取り組み、2014年度と15年度に全国の劇団や劇場・ホール・博物館等文化施設を対象に、舞台芸術・芸能関係の映像資料の所蔵状況に関するアンケート調査を実施しました。その結果、多くの劇団や劇場で公演を記録した映像が「撮りっぱなし」のまま死蔵されていることがわかりました。VHSに記録された映像など経年劣化に晒されているものも多く、デジタル化と適切な保存が急務であることは明らかでした。舞台芸術の歴史を刻む貴重な公演映像を収集・保存して未来に継承していくのはエンパクの社会的使命である――それ以来そういう思いを抱き続けてきましたが、膨大な舞台公演映像の収集やデジタル化は、映画にとっての国立映画アーカイブのように国家的な財源がなければ難しいという現実がありました。",
        "par04": "しかしこのたびEPADで舞台公演の新規収録だけではなく既存映像もデジタル化および収集されたことにより、約1,300本のデジタル映像とフライヤーや舞台写真等の関連資料をエンパクのデジタルアーカイブに保存し未来に継承できることになりました。そしてその貴重な映像や関連資料を活用すべく、このJDTAを立ち上げた次第です。ここには、現代演劇・伝統芸能・舞踊の三分野の資料が集まっていますが、現代演劇だけを見ても老舗の新劇から若手の作品、児童演劇、ミュージカル、2.5次元など、多種多様な公演の情報が凝縮されています。ダンス映像の情報も豊富で、さらに、EPADにより収集された戯曲約550作品のリストも掲載したことで、さながら日本の舞台芸術の鮮やかな見取り図を見ているようです。",
        "par05": "JDTAで情報検索が容易にできることで、こうした資料が作り手や研究者だけでなく一般の演劇・舞踊・伝統芸能ファンの方々やこれまで劇場に縁遠かった方がたにも身近になれば、観劇文化の裾野が広がり、コロナ禍に苦しむ舞台芸術界の活性化と持続的な収益向上に貢献できるのではないかと私たちは考えています。JDTAは日本語と英語の二か国語サイトなので、海外にも日本の素晴らしい演劇文化をより広く知ってもらえる契機ともなるでしょう。",
        "par06": "なお、EPADにより著作権処理がなされた映像は順次配信サイトで公開されていく予定ですが（権利処理された映像については、3分ほどの抜粋映像を本サイトでも公開しています）、ほとんどの映像は事前予約の上エンパクにご来館いただければ館内で閲覧できます。",
        "par07": "エンパクは、1928年に坪内逍遙が創立して以来、アジアで唯一、世界でも稀有な演劇専門総合博物館として古今東西の貴重な演劇資料の収集と保存、公開に取り組んできました。また、デジタルアーカイブの公開も2001年とかなり早く、豊富な実績があります。2020年度のEPAD事業では約1,300本の舞台公演映像と関連資料に加えて、一般社団法人日本劇作家協会が収集した約550本の戯曲データ等を収集することができましたが、これはまだ序章に過ぎません。全国にはまだまだ膨大な舞台公演映像が日の目を見ることなく眠っています。これを機に貴重な舞台映像や戯曲が散逸することなく、あるいは死蔵されることなくエンパクに集まるようになっていけば、日本の舞台芸術の多様で豊かな歴史が確実に未来に継承されていきます。みなさまのさらなるご協力とご支援を賜りますよう、どうぞよろしくお願い申し上げます。",
        "par08": "最後に、貴重な資料をご提供くださいましたみなさまに篤く御礼申し上げます。<br>このサイトから、少しでも多くのみなさまが演劇や舞踊、伝統芸能の劇場や配信サイトを訪れてくださることを心から願っています。",
        "par09": "2022年度、EPAD2022実行委員会は文化庁令和3年度補正予算 文化芸術振興費補助金 統括団体によるアートキャラバン事業（コロナ禍からの文化芸術活動の再興支援事業）に採択され、2020年度に収集した現代演劇・舞踊・伝統芸能の三分野にわたる舞台公演映像約1,300本に加えて、約400本の公演映像を新たに収集しました。2020年度と同様に、それらの舞台公演映像の情報は、チラシや舞台写真、権利処理が完了した作品の3分映像等とともに当サイトで検索可能となっています。",
        "par10": "また、今回EPADが収集した公演映像もすべて演劇博物館に寄贈されており、ほとんどの映像は演劇博物館のAVブースでご覧いただけます（事前予約制）。",
        "par11": "より充実したJDTAを多くのみなさまにご利用いただけますよう、心から願っております。",
        "par12": "2023年度、一般社団法人EPADが文化庁令和4年度補正予算 文化芸術振興費補助金 統括団体による文化芸術需要回復・地域活性化事業（アートキャラバン2）の助成を受けて実施した「日本の舞台映像のデジタルシアター化支援事業（配信の取組）－EPAD2023－」にて収集した公演映像の内、490作品の情報を新たに当サイトに掲載しました。",
        "sign": {
            "_01": "2021年2月",
            "_02": "2023年2月",
            "_03": "<span>Japan Digital Theatre Archives</span> <span>制作チーム代表</span><br><span>早稲田大学坪内博士記念演劇博物館</span> <span>館長</span>",
            "_04": "岡室美奈子",
            "_05": "2024年1月",
            "_06": "<span>早稲田大学演劇博物館館長</span>",
            "_07": "児玉竜一",
        },
        "title": {
            "credits": "クレジット",
        },
    },
    "colon": "：",
    "detail": {
        "annotation" : {
            "multiple": "この公演に関する映像資料は複数あります。「<」「>」をクリックしてご確認ください。",
            "copyright": "画像・映像データの著作権は各著作物の著作者に帰属します。無断で複製・転載することはできません。",
            "video": "公演映像のキャプチャが含まれている場合があります。"
        },
        "heading": {
            "about": "公演／作品概要",
            "cast": "出演者",
            "company": "カンパニー／興行主体情報",
            "image": "画像",
            "info": "公演情報",
            "notes": "備考",
            "program": "上演作品／演目 ",
            "relation": "関連する演劇",
            "staff": "スタッフ",
            "video": "映像",
            "isReferencedBy": "掲載情報について",
        },
        "video": {
            "date": "映像収録日",
            "howUse": "ご利用方法はこちら",
            "link": "映像配信サイトはこちら",
            "number": "映像資料番号",
            "private": "この作品は、演劇博物館で視聴できません",
            "public": "この作品は、事前予約により演劇博物館で視聴できます",
            "rightHolder": "権利者",
            "time": "収録時間",
            "web": "Web",
            "distributed": "権利処理が完了しており、<br>外部の配信サイトで配信されている、<br>または今後配信の可能性のある作品です。"
        },
    },
    "enpaku": "早稲田大学演劇博物館",
    "enpaku_address": "〒169-8050　東京都新宿区西早稲田1-6-1",
    "item": {
        "label": {
            "award": "受賞歴",
            "company": "カンパニー／興行主体",
            "endDate": "千秋楽",
            "keyword": "キーワード",
            "location": "所在",
            "performer": "演出／振付",
            "sponsor": "主催",
            "startDate": "初日",
            "theater": "会場",
            "theaterOther": "ほか",
            "title": "公演名",
            "web": "Webサイト",
            "year": "上演年",
            "db": "早大文化資源DBへ"
        }
    },
    "menu": {
        "home_lead": "日本の舞台公演映像の情報検索サイトです。JDTAでは、<br />EPAD<small>（文化庁文化芸術収益力強化事業の一つ）</small>で<br class=\"tb-hidden\" />収集した演劇・舞踊・伝統芸能の<br class=\"tb-hidden\" />舞台公演映像の情報を検索できます。<br />早稲田大学演劇博物館が運営しています。",
    },
    "pageTitle": {
        "about": "JDTAについて",
        "detail": "公演詳細",
        "news": "ニュース",
        "playtext": "戯曲目録",
        "privacy": "プライバシーポリシー",
        "search": "検索",
        "howto": "本サイトの使い方",
        "terms": "利用規約",
        "top": "トップ",
    },
    "playtext": {
        "description": "緊急舞台芸術アーカイブ＋デジタルシアター化事業（EPAD）にて収集・デジタル化された戯曲の一覧です。一般社団法人日本劇作家協会がEPADから受託し、全国から多数の作品が集められました。協会が制作したポータルサイト<a class=\"c-text__link c-text__link--external\" href=\"https://playtextdigitalarchive.com/\" data-txt=\"「戯曲デジタルアーカイブ」\" target=\"_blank\"><span>「戯曲デジタルアーカイブ」</span></a>ではこの一覧に掲載されたほとんどの戯曲が無料で閲覧、ダウンロードできるほか、上演許諾の申請先を調べることができます。ただしリストに「<span class=\"c-text_logo\"></span>」がある作品は、 戯曲デジタルアーカイブには非掲載・当一覧のみの掲載になります。該当作品は演劇博物館で館内閲覧が可能です。<br>なお、当一覧の戯曲は、このサイトで検索できる舞台公演映像とは別に収集されたものです。",
        "label": {
            "id": "ID",
            "author": "作者",
            "yearIssued": "初演／発行年",
            "people": "上演人数（目安）",
            "title": "作品名",
        }
    },
    "privacy": {
        "about_privacy": {
            "_01": {
                "heading": "個人情報の収集と利用目的について",
                "par": "個人情報とは、特定の個人を識別することのできる情報を意味します。当サイトの利用者から提供を受けた個人情報については、あらかじめ提示した利用目的以外に用いることはせず、法令及び早稲田大学の「個人情報の保護に関する規則」を遵守して適切な取扱いを行います。"
            },
            "_02": {
                "heading": "第三者への提供について",
                "par": "当サイトでは、個人情報を本人の同意なしに第三者に提供することはいたしません。ただし、法律の定める例外については、本人の同意なしに情報を提供することがあります。"
            },
            "_03": {
                "heading": "Google Analyticsの使用",
                "list01": "Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用",
                "list01_url": "https://policies.google.com/technologies/partner-sites",
                "list02": "Google プライバシー ポリシー",
                "list02_url": "https://policies.google.com/privacy",
                "par": "当サイトでは、利用状況の把握と、サイトのより良い運用のためにGoogle Analyticsを利用しています。Google Analyticsではデータ収集のためCookieを使用しています。このデータは匿名で収集されており、個人を特定するものではありません。また、ブラウザの設定等でCookieを無効にすることにより、収集を拒否することができます。収集されたデータは、Google社のプライバシーポリシーに基づいて管理されています。Google Analyticsの利用規約・プライバシーポリシーについては、Google Analyticsのウェブサイトでご確認ください。"
            },
            "_04": {
                "heading": "プライバシーポリシーに関するお問い合わせ先",
            },
        },
        "enquiries": {
            "address": "〒169-8050　東京都新宿区西早稲田1-6-1",
            "company": "早稲田大学坪内博士記念演劇博物館",
            "email": "E-MAIL：enpaku.jdta（アット）gmail.com ※（アット）を＠に変換してください",
            "fax": "FAX：03-5273-4398",
            "tel": "TEL：03-5286-1829（平日 9時～17時）",
        },
        "lead": "当サイトを閲覧・利用することで個人情報が収集されることはありませんが、お問い合わせなどの目的で、利用者から個人情報の提供を受けることがあります。当サイトの運営に際し、利用者から提供を受けた個人情報については、プライバシーの保護に配慮し、以下の方針のもとで適切な取扱いを行います。",
        "title": {
            "about_privacy": "個人情報について"
        }
    },
    "resultHeader": {
        "checkbox": {
            "archive": "抜粋映像あり",
            "award": "受賞歴あり",
        },
        "narrowDown": "絞り込み：",
        "number": " 件",
        "genre": "分野",
        "order": "表示順：",
        "order_ascending": "・昇順",
        "order_descending": "・降順",
        "select": {
            "imageType": "画像の種類",
            "flyer": "フライヤー",
            "stage": "舞台写真",
            "poster": "その他",
        },
        "title": {
            "search": "公演リスト",
            "result": "公演検索結果",
            "resultImages": "画像検索結果",
            "resultVideos": "映像検索結果",
        },
    },
    "searchHeader": {
        "reset": "条件をリセット",
        "reset_all": "全ての条件をリセット",
        "detail": "詳細設定",
        "detail_addWords": "追加ワード",
        "detail_addWords_include": "を含む公演",
        "detail_addWords_notInclude": "を含まない公演",
        "detail_category": "分野",
        "detail_category_dance": "舞踊",
        "detail_category_drama": "演劇",
        "detail_category_tradition": "伝統芸能",
        "detail_genre": "（選択してください）",
        "detail_input_placeholder": "キーワード",
        "detail_other": "その他<span class=\"u-sp-hidden\">条件",
        "detail_other_award": "受賞歴あり",
        "detail_other_video": "抜粋映像あり",
        "detail_year": "上演年",
        "input_placeholder": "全公演から探す",
        "input_submit": "この条件で検索",
        "range_mark": "~",
        "target": "検索対象：",
        "target_image": "画像",
        "target_performance": "公演",
        "target_video": "映像",

        "awg_company": "カンパニー／興行主体",
        "awg_company_t": "カンパニー／興行主体（よみ）",
        "awg_title": "公演名",
        "awg_title_t": "公演名（よみ）",
        "awg_performanceTitle": "上演作品／演目",
        "awg_performanceTitle_t": "上演作品／演目（よみ）",
        "awg_sponsor": "主催",
        "awg_theater": "会場",
        "awg_theater_t": "会場（よみ）",
        "awg_keyword": "キーワード",
        "awg_staff": "スタッフ（名前）",
        "awg_staff_t": "スタッフ（名前よみ）",
        "awg_staff_role": "スタッフ（役割）",
        "awg_actor": "出演者（名前）",
        "awg_actor_t": "出演者（名前よみ）",
        "awg_actor_character": "出演者（役名）",
    },
    "search_keyword": "キーワードから探す",
    "tagCloud": {
        "title": "検索候補",
    },
    "terms": {
        "about": {
            "caption01": "※視聴・閲覧できる作品は、予告なく変更となる場合があります。予めご了承ください。",
            "list01": "Japan Digital Theatre Archives（JDTA　以下、当サイト）は、緊急舞台芸術アーカイブ＋デジタルシアター化支援事業（以下、EPAD）で収集された現代演劇・舞踊・伝統芸能の三分野にわたる舞台公演映像の情報検索特設サイトです。演劇情報検索ができるほか、EPADでデジタル化された戯曲目録を掲載しています。早稲田大学坪内博士記念演劇博物館（以下、演劇博物館）が運用しています。",
            "list02": "EPAD事業により舞台映像の配信に向けた権利処理が完了した作品については、当サイト上で3分程度の抜粋映像をご覧いただけます。配信されているかについては、劇団ホームページ等でご確認ください。",
            "list03": "当サイトでは公演映像全編を見ることはできませんが、当サイトに掲載した舞台作品・戯曲の内、コンテンツ提供者の確認を得たものは、事前予約により、演劇博物館において視聴・閲覧が可能です。ご利用方法は以下、演劇博物館Webサイトにてご確認ください",
            "list04": "当サイト上の舞台公演映像や戯曲に関する情報、キーワードは、コンテンツ提供者からいただいたものをもとに掲載しています。そのため表記の不統一や情報の多寡等があることをご了承ください。",
        },
        "copyright": {
            "list01": "当サイトおよびデータベースの著作権は、演劇博物館に帰属します。",
            "list02": "当サイト上の画像・映像データ、公演／作品概要、カンパニー／興行主体紹介は、コンテンツ提供者の確認を得て掲載しており、著作権は各著作物の著作者に帰属します。私的使用など法律で認められる一部の例外を除いて、当サイトの閲覧者が無断で複製や転載をすることはできません。",
            "list03": "公演／作品概要、カンパニー／興行主体紹介を除く当サイト上の文字情報（公演および資料に関するメタデータ）は、「CC0（CC0 1.0 全世界 パブリック・ドメイン提供）」の規定により利用できます。",
            "url": "https://creativecommons.org/publicdomain/zero/1.0/deed.ja",
        },
        "disclaimer": "当サイトに記載されている情報は、提供者からの情報をもとに作成されています。利用者が当サイトの情報を用いて行う一切の行為について、演劇博物館は何ら責任を負うものではありません。また、当サイトの内容、利用条件、URL(アドレス)などを、予告なしに変更または削除する場合があります。予めご了承ください。",
        "enquiries": {
            "company": "早稲田大学坪内博士記念演劇博物館",
            "email": "E-MAIL：enpaku.jdta（アット）gmail.com ※（アット）を＠に変換してください",
            "fax": "FAX：03-5273-4398",
            "tel": "TEL：03-5286-1829（平日 9時～17時）",
        },
        "link": {
            "list01": "当サイトへのリンクは自由に行っていただいて結構です。ただし、以下のいずれかに該当するか、またはそのおそれがある場合は、リンクの設定をご遠慮ください。",
            "list01_01": "当サイト、または他社（者）・他団体を誹謗中傷したり、信用失墜を意図したりする内容を含んだWebサイト。",
            "list01_02": "当サイト、または他社（者）・他団体の著作権、商標権等の知的財産権、財産、プライバシーもしくは肖像権その他の権利を侵害する行為、または侵害するおそれのあるWebサイト。",
            "list01_03": "法律、条例、規則を含む法令または公序良俗に違反しているWebサイト、および本サイトの運営を妨害するおそれのあるWebサイト。",
            "list02": "なお、当サイトのURL（アドレス）、内容は事前に予告することなく変更する場合がありますので予めご了承ください。",
            "list03": "リンクを張る際は、該当ページが当サイト内にあることを明記してください。",
        },
        "systemRequirements": {
            "lead": "本サイトでは、より安全で快適にご利用いただくために下記のブラウザを推奨致します。",
            "mac": "Macをご利用の場合",
            "mac_chrome": "Google Chrome 最新バージョン",
            "mac_firefox": "Mozilla Firefox 最新バージョン",
            "mac_safari": "Safari 5.0以上",
            "windows": "Windowsをご利用の場合",
            "windows_chrome": "Google Chrome 最新バージョン",
            "windows_edge": "Microsoft Edge 最新バージョン",
            "windows_firefox": "Mozilla Firefox 最新バージョン",
            "windows_ie": "Microsoft Internet Explorer 11",
        },
        "title": {
            "about": "当サイトについて",
            "copyright": "著作権について",
            "disclaimer": "免責事項",
            "enquiries": "お問合せ",
            "link": "当サイトへのリンクについて",
            "systemRequirements": "推奨環境",
        },
    },
};