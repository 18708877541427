import { MenuPostAdaptItem, OmekaThumbnails } from '@/enpaku';
import { site } from '@/util/config';
import { apiGet } from './apiGet';
import { FetchMenuPosts } from './interface';

const endpoint = site.apiEndpoints.randomThumbnails;

export const fetchMenuPosts: FetchMenuPosts = async ({
    lang,
    limit,
    condition = {},
}) => {
    const { headers, data } = await apiGet<FetchRandomThumbnailRow[]>(endpoint, {
        lang: lang || 'ja',
        limit: limit || 12,
        search: condition.searchKeyword ? condition.searchKeyword : undefined,
        r: (new Date()).getTime(),
    });
    const recordset: MenuPostAdaptItem[] = data.map((r: any) =>
        representationThumnail(r, lang)
    );
    const totalRecords =
        'jdta-total-results' in headers
            ? Number(headers['jdta-total-results'])
            : recordset.length;

    return { totalRecords, recordset };
};

type FetchRandomThumbnailRow = {
    id: string;
    identifier: string;
    title: string;
    applicantAgent: string;
    thumbnail_display_urls: OmekaThumbnails;
};

const representationThumnail = (
    row: FetchRandomThumbnailRow,
    lang: string
): MenuPostAdaptItem => {
    const { id, identifier, title, thumbnail_display_urls, applicantAgent } = row;
    const path = (lang === 'en' ? '/en' : '') + '/detail/' + identifier;
    const image = thumbnail_display_urls.large || '/files/no-image.png';
    return { id, identifier, title, path, image, applicantAgent };
};
