
import { defineComponent } from "vue";
import {epksrc} from "@/helper";
import { dictionary } from "@/compositions/pageComposition";

export default defineComponent({
  name: "ExternalLinks",
  setup() {
    return {
      lang: dictionary,
      epksrc
    };
  }
});
