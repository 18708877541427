import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "js-menuLogo",
  class: "c-menu__logo"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("img", {
      class: "c-menu__logo_img",
      src: _ctx.epksrc('/assets/img/logo.svg'),
      alt: _ctx.siteName
    }, null, 8, ["src", "alt"])
  ]))
}