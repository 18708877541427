import { site } from '@/util/config';
import { apiGet } from './apiGet';
import { FetchItemResources } from './interface';

const endpoint = site.apiEndpoints.itemResources;

export const fetchItemResources: FetchItemResources = async ({
    searchType,
    condition = {
        fulltext_search: '',
        epadCategory: [],
        dcterms__date: ['', ''],
        dcepk__award: '',
        dcepk__youTubeContentID: '',
        extra: [],
    },
    page = 1,
    perPage = 25,
    sortBy,
    sortOrder,
    lang = 'ja',
}) => {
    const payload = {
        searchType, lang,
        page,
        per_page: perPage,
        sort_by: sortBy,
        sort_order: sortOrder,
        condition,
    };

    const { headers, data: recordset } = await apiGet(endpoint, payload);

    const totalRecords =
        'jdta-total-results' in headers
            ? Number(headers['jdta-total-results'])
            : recordset.length;

    return { totalRecords, recordset };
};
