const ja_JP = require('./lang/ja_JP');
const en_US = require('./lang/en_US');

const switchLangOnServer = (data) => {
    return [
        "<?php if($lang === 'en'){ ?>",
        data.en_US,
        "<?php } else { ?>",
        data.ja_JP,
        "<?php } ?>"
    ].join('');
};
const requestUriOnServer = "<?php echo $_SERVER['REQUEST_URI']; ?>";

module.exports = {
    home: {
        path: requestUriOnServer,
        title: 'Japan Digital Theatre Archives',
        description: switchLangOnServer({ 'ja_JP': ja_JP.site.description, 'en_US': en_US.site.description }),
        scriptOptions: {
            layoutType: 'top',
        }
    },
    search: {
        path: requestUriOnServer,
        title: switchLangOnServer({ 'ja_JP': ja_JP.pageTitle.search, 'en_US': en_US.pageTitle.search }),
        description: switchLangOnServer({ 'ja_JP': ja_JP.site.description, 'en_US': en_US.site.description }),
        scriptOptions: {
            layoutType: 'list',
        }
    },
    detail: {
        path: requestUriOnServer,
        title: '<?php echo $title; ?>',
        description: '<?php echo $description; ?>',
        ogImage: '<?php echo $ogImage; ?>',
    },
    news: {
        path: requestUriOnServer,
        title: switchLangOnServer({
            'ja_JP': ja_JP.pageTitle.news,
            'en_US': en_US.pageTitle.news,
        }),
        description: switchLangOnServer({ 'ja_JP': ja_JP.site.description, 'en_US': en_US.site.description }),
    },
    about: {
        path: requestUriOnServer,
        title: switchLangOnServer({
            'ja_JP': ja_JP.pageTitle.about,
            'en_US': en_US.pageTitle.about,
        }),
        description: switchLangOnServer({ 'ja_JP': ja_JP.site.description, 'en_US': en_US.site.description }),
    },
    privacy: {
        path: requestUriOnServer,
        title: switchLangOnServer({
            'ja_JP': ja_JP.pageTitle.privacy,
            'en_US': en_US.pageTitle.privacy,
        }),
        description: switchLangOnServer({ 'ja_JP': ja_JP.site.description, 'en_US': en_US.site.description }),
    },
    playtext: {
        path: requestUriOnServer,
        title: switchLangOnServer({
            'ja_JP': ja_JP.pageTitle.playtext,
            'en_US': en_US.pageTitle.playtext,
        }),
        description: switchLangOnServer({ 'ja_JP': ja_JP.site.description, 'en_US': en_US.site.description }),
        scriptOptions: {
            layoutType: 'list',
        }
    },
    terms: {
        path: requestUriOnServer,
        title: switchLangOnServer({
            'ja_JP': ja_JP.pageTitle.terms,
            'en_US': en_US.pageTitle.terms,
        }),
        description: switchLangOnServer({ 'ja_JP': ja_JP.site.description, 'en_US': en_US.site.description }),
    },
};
