import { MenuPostAdaptItem } from './enpaku';
import {
    displayMode,
    getNextIndexAndItem,
    loadedDisplay,
    loadThumbnails,
    prepareNextItem,
    searchResultsDisplay,
    searchThumbnails,
    setDisplayMode,
    swapThumbnail
} from './compositions/menuThumbnailsComposition';

interface MenuAdapter {
    clearSearchResults(): void;
    preparePosts(): Promise<void>;
    prepareSearchResult(keyword: string): Promise<void>;
    prepareRandomSwap(): Promise<{ item: MenuPostAdaptItem; index: number }>;
    getDisplay(): MenuPostAdaptItem[];
}

const waitImageLoaded = (src: string) => new Promise<void>(
    resolve => {
        const img = new Image;
        img.src = src;
        img.onload = () => resolve();
    }
);

const adapter: MenuAdapter = {
    clearSearchResults() {
        setDisplayMode('loaded');
    },
    async preparePosts() {
        await loadThumbnails();
    },
    async prepareSearchResult(keyword: string) {
        await searchThumbnails(keyword);
        setDisplayMode('searchResult');
    },
    async prepareRandomSwap() {
        await prepareNextItem();
        const { index, item } = getNextIndexAndItem();
        if (!item || index === undefined) throw new Error('prepare failed');
        await waitImageLoaded(item.image);
        return { index, item };
    },
    getDisplay() {
        switch (displayMode()) {
            case 'loaded': {
                swapThumbnail();
                return loadedDisplay();
            }
            case 'searchResult':
                return searchResultsDisplay();
            default:
                return [];
        }
    },
};

export default adapter;
