
import { defineComponent } from "vue";

export default defineComponent({
  name: "Error",
  props: {
    code: Number,
    message: String,
  },
});
