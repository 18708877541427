const siteHost = "https://front-check.enpaku-jdta.jp"; // without last slash.
const omekaHost = "";

module.exports = {
  // Site information
  siteHost,
  siteName: "JAPAN DIGITAL THEATRE ARCHIVES",
  siteTitle: "JDTA",
  description:
    "Japan Digital Theatre Archives (JDTA) は、緊急舞台芸術アーカイブ＋デジタルシアター化支援事業（EPAD）の一環として早稲田大学演劇博物館が開設した、現代演劇・舞踊・伝統芸能の三分野にわたる舞台公演映像の情報検索特設サイトです。",

  // head tag settings
  favicon: "/assets/img/favicon.svg",
  icon256: "/assets/img/icon.png",
  ogImage: "/assets/img/ogimage.jpg",
  // ogImageSmall: '/assets/img/ogimage_small.jpg', // サーバー側プログラムへ埋め込み
  fbAppId: "",
  og: true,
  twitter: true,
  fb: false,
  gtag: false,

  // API settings
  omekaHost,
  apiEndpoints: {
    theatricalResources:
      "https://vcugslznwtbr2lzfqplmxshxpa0euahd.lambda-url.ap-northeast-1.on.aws/",
    stillImages:
      "https://nvibwgvl37pk65q2remdpqyzx40ltnbr.lambda-url.ap-northeast-1.on.aws/",
    itemResources:
      "https://mhwsoekxgctd6vl2aytae5hqxa0gquoj.lambda-url.ap-northeast-1.on.aws/",
    randomKeywords:
      "https://zykjuc77n6r6airszvnmsyhfei0kdskn.lambda-url.ap-northeast-1.on.aws/",
    randomThumbnails:
      "https://zz4nullsnlji25ownz7zzmmvgy0pcohi.lambda-url.ap-northeast-1.on.aws/",
    playtexts:
      "https://wlu2kx6s5rpahtsxxl3jfd2s2y0toliu.lambda-url.ap-northeast-1.on.aws/",
    announcements:
      "https://vmkdae7rh5ggvll26hoz5vtk7a0jbsfc.lambda-url.ap-northeast-1.on.aws/",
  },
};
