import { pages } from '@/util/config';
import adapter from './adapter';

const script = require('@/templates/script/script.js');
export const useScript = (pageKey: string) => {
    const { scriptOptions = {} } = pages[pageKey];
    script(adapter, scriptOptions);
};
export const multilineAccordion: (htmlElement: HTMLElement) => void =
    script.multilineAccordion;

export const epkhref = (path: string) => {
    const urlLangEn = window.location.pathname.match(/^\/en($|\/)/);
    return (urlLangEn ? '/en' : '') + path;
};

export const epksrc = (path: string) => path;

export const goThere = ($event?: Event) => {
    // window.location.href = href;
};
