import { reactive, computed, ref } from 'vue';
import { TheatricalResourceItem, StillImageItem, ItemResourceItem } from '@/enpaku';
import { GalleryItem, photoSwipeStillImageGalleryItems } from '@/templates/script/openPhotoSwipeGallery';
import { conditionOutline, applyState } from './util';
import { dictionary, language } from '@/compositions/pageComposition';
import { LoadingState, LoadingStateType } from './LoadingState';
import storage from '@/util/storage';
import * as fontplus from '@/fontplus';
import { EnpakuRecordsetRequest, EnpakuSearchCondition, ExtraSearchEntry, RecordsetType } from '@/api/interface';
import { fetchItemResources } from '@/api/fetchItemResources';
import { fetchStillImages } from '@/api/fetchStillImages';
import fetchTheatricalResources from '@/api/fetchTheatricalResources';
const { site } = require('@/util/config');

const overviewPerPage = site.linesPerPage || 60;
const imagePerPage = site.imagelinesPerPage || 60;
const videoPerPage = site.videolinesPerPage || 30;

interface TheatreListStateBase {
    loading: boolean;
    error: string | undefined;
    // recordset: TheatricalResource[];
    totalRecords: number;
    condition: Partial<EnpakuSearchCondition> | undefined;
    perPage: number;
    page: undefined | number;
    sortBy: undefined | string;
    sortOrder: undefined | 'asc' | 'desc';
    loadingState: LoadingStateType;
    // resultType: RecordsetType;
}
type TheatreListState = TheatreListStateBase & (
    {
        resultType: undefined;
        recordset: [];
    } | {
        resultType: 'overview';
        recordset: TheatricalResourceItem[];
    } | {
        resultType: 'image';
        recordset: StillImageItem[];
    } | {
        resultType: 'video';
        recordset: ItemResourceItem[];
    }
)

interface SearchCondition extends EnpakuSearchCondition {
    /**
     * 絞込特定：分野
     */
    specifiedEpadCategory: string;

    /**
     * 絞込特定：画像種類
     */
    specifiedMaterialType: string;

    /**
     * 絞込特定：映像配信あり
     */
    specifiedYouTubeContentID: '1' | '';

    /**
     * 絞込特定：受賞歴あり
     */
    specifiedAward: '1' | '';
}

const createState = (): TheatreListState => ({
    perPage: overviewPerPage,
    condition: undefined,
    page: undefined as number | undefined,
    sortBy: 'TheatricalResource:dcepk:titleTranscription:1',
    sortOrder: 'asc' as "asc" | "desc" | undefined,

    loading: false,
    recordset: [],
    totalRecords: 0,
    error: undefined,

    loadingState: LoadingState.notLoading,
    resultType: undefined
})

export const state = reactive<TheatreListState>(createState());
export const flushAllCondition = () => {
    const searchType = searchCondition.searchType;
    // applyState(state, createState());
    applyState(searchCondition, createSearchCondition(searchType));
    storage.removeItem(saveStateSessionKey);
}

export const currentPage = computed<number>(() => state.page || 1);
export const lastPage = computed<number>(
    () => Math.ceil(state.totalRecords / state.perPage) || 1
);
export const pageset = computed<number[]>(() => {
    if (!state.totalRecords || !state.page) return [];

    const aside = 2;
    const prevs = aside + Math.max(currentPage.value + aside - lastPage.value, 0);
    const begin = Math.max(currentPage.value - prevs, 1);
    const last = Math.min(begin + aside * 2, lastPage.value);

    // const set = 5;
    // const begin = currentPage.value - ((currentPage.value - 1) % set);
    // const last = Math.min(begin + (set - 1), lastPage.value);

    const pageset = new Array(last - begin + 1)
        .fill(begin)
        .map((v, i) => v + i);
    return pageset;
});
export const getConditionOutline = () => conditionOutline(state.condition);
// export const getSearchConditionOutline = () => conditionOutline({
//     ...JSON.parse(JSON.stringify(searchCondition)),
//     dcterms__date: dateCondition.value
// });


export const photoSwipeImageGalleryItems = computed<GalleryItem[]>(
    () => photoSwipeStillImageGalleryItems(state.recordset || [], "listItem")
);
/**
 * 公演リストの結果取得
 */
export const recordset = computed<TheatricalResourceItem[]>(() => {
    return state.resultType === 'overview'
        ? state.recordset
        : [];
});

/**
 * 画像検索の結果取得
 */
export const imageRecordset = computed<StillImageItem[]>(() => {
    return state.resultType === 'image'
        ? state.recordset
        : [];
});

/**
 * 映像検索の結果取得
 */
export const videoRecordset = computed<ItemResourceItem[]>(() => {
    return state.resultType === 'video'
        ? state.recordset
        : [];
});

type Unregisterer = () => void;
const fontplusUnregisterRef = ref<Unregisterer>();

export const fetchRecordset = async (payload: Partial<EnpakuRecordsetRequest> = {}) => {

    if(state.loading) {
        // console.log('REJECT fetch request (now loading)');
        return;
    }

    const {
        condition = state.condition,
        page = state.page || 1,
        sortBy = state.sortBy,
        sortOrder = state.sortOrder,
    } = payload;

    const searchType = searchCondition.searchType || 'overview';

    state.loading = true;

    const lang = language.value === 'en_US' ? 'en' : 'ja';

    let api: any;
    switch (searchType) {
        case 'overview':
            state.perPage = overviewPerPage;
            api = fetchTheatricalResources;
            break;
        case 'image':
            state.perPage = imagePerPage;
            api = fetchStillImages;
            break;
        case 'video':
            state.perPage = videoPerPage;
            api = fetchItemResources;
            break;
        default:
            api = fetchTheatricalResources;
    }
    try {
        const { totalRecords, recordset } = await api({
            searchType,
            condition,
            page,
            sortBy,
            sortOrder,
            perPage: state.perPage,
            lang,
        });

        state.condition = condition;
        state.totalRecords = totalRecords;
        state.sortBy = sortBy;
        state.sortOrder = sortOrder;
        state.page = page;
        state.resultType = searchType;
        state.recordset = recordset;

        saveAsLastRequest();

        fontplusUnregisterRef.value && fontplusUnregisterRef.value();
        fontplusUnregisterRef.value = fontplus.registerText(JSON.stringify(recordset));
        await fontplus.loadFontplus('theatreListComposition');

    } catch (error) {
        state.error = error instanceof Error ? error.message : String(error);
    } finally {
        state.loading = false;
    }
}

export const setPage = async (page: number) => {
    const { condition, sortBy, sortOrder } = state;
    state.loadingState = LoadingState.pagingOrSorting;
    await fetchRecordset({
        condition,
        page,
        sortBy,
        sortOrder,
    } as any);
    state.loadingState = LoadingState.notLoading;
}

export const toggleSortOrder = async ({ sortBy, sortOrder: specifiedOrder }: Partial<typeof state>) => {
    state.loadingState = LoadingState.pagingOrSorting;
    const {
        condition,
        sortBy: currentSortBy,
        sortOrder: currentSortOrder,
    } = state;

    const sortOrder =
        specifiedOrder || sortBy !== currentSortBy
            ? 'asc'
            : currentSortOrder === 'asc'
                ? 'desc'
                : 'asc';

    await fetchRecordset({
        condition,
        page: 1,
        sortBy,
        sortOrder,
    } as any);
    state.loadingState = LoadingState.notLoading;
}

/**
 * 検索ボタンクリック / メインテキスト検索のEnter / 虫眼鏡クリック
 */
export const search = async () => {
    searchCondition.specifiedEpadCategory = '';
    searchCondition.specifiedMaterialType = '';
    searchCondition.specifiedYouTubeContentID = '';
    searchCondition.specifiedAward = '';

    state.loadingState = LoadingState.searching;
    await fetchRecordset({
        condition: requestCondition(),
        page: 1
    });
    state.loadingState = LoadingState.notLoading;
};

/**
 * 絞込
 */
export const additonalSearch = async () => {
    state.loadingState = LoadingState.additionalSearching;
    await fetchRecordset({
        condition: requestCondition(),
        page: 1
    });
    state.loadingState = LoadingState.notLoading;
};

/**
 * キーワードクリック
 */
export const toggleKeyword = async (keyword: string) => {
    const specifiedKeyword = searchCondition.specifiedKeyword;
    if (specifiedKeyword === keyword) {
        searchCondition.specifiedKeyword = '';
    } else {
        searchCondition.specifiedKeyword = keyword;
    }
    await search();
}

//
// [search]
//
const dcterms__date_0 = '1950';
const dcterms__date_1 = new Date().getFullYear().toString();

const createExtraTypeOptions = (dic: any, language: string) => {
    const addTranscription = language === 'ja_JP';

    const options: { value: string; label: string }[] = [];

    // (選択してください)
    options.push({
        value: '',
        label: dic.searchHeader.detail_genre
    });

    // カンパニー／興行主体
    options.push({
        value: 'Agent:foaf:name@dcepk:applicantAgent',
        label: dic.searchHeader.awg_company
    });
    if (addTranscription) options.push({
        value: 'Agent:dcepk:agentTranscription@dcepk:applicantAgent',
        label: dic.searchHeader.awg_company_t
    });
    // 公演名
    options.push({
        value: 'TheatricalResource:dcterms:title',
        label: dic.searchHeader.awg_title
    });
    if (addTranscription) options.push({
        value: 'TheatricalResource:dcepk:titleTranscription',
        label: dic.searchHeader.awg_title_t
    });
    // 演目名
    options.push({
        value: 'PerformanceRecord:dcterms:title',
        label: dic.searchHeader.awg_performanceTitle
    });
    if (addTranscription) options.push({
        value: 'PerformanceRecord:dcepk:titleTranscription',
        label: dic.searchHeader.awg_performanceTitle_t
    });
    // 主催
    options.push({
        value: 'TheatricalResource:dcepk:labelOfOrganizer',
        label: dic.searchHeader.awg_sponsor
    });
    // 会場
    options.push({
        value: 'TheatricalResource:dcepk:performanceVenue',
        label: dic.searchHeader.awg_theater
    });
    // 会場よみ
    if (addTranscription) options.push({
        value: 'TheatricalResource:dcepk:performanceVenueTranscription',
        label: dic.searchHeader.awg_theater_t
    });
    // キーワード
    options.push({
        value: ':keyword',
        label: dic.searchHeader.awg_keyword
    });
    // スタッフ（名前）/（名前よみ）/（役割）
    options.push({
        value: 'Agent:foaf:name@dcterms:contributor',
        label: dic.searchHeader.awg_staff
    });
    if (addTranscription) options.push({
        value: 'Agent:dcepk:agentTranscription@dcterms:contributor',
        label: dic.searchHeader.awg_staff_t
    });
    options.push({
        value: 'Agent:dcepk:roleName@dcterms:contributor',
        label: dic.searchHeader.awg_staff_role
    });
    // 出演者（名前）/（名前よみ）/（役名）
    options.push({
        value: 'Agent:foaf:name@dcepk:actor',
        label: dic.searchHeader.awg_actor
    });
    if (addTranscription) options.push({
        value: 'Agent:dcepk:agentTranscription@dcepk:actor',
        label: dic.searchHeader.awg_actor_t
    });
    options.push({
        value: 'Agent:dcepk:characterName@dcepk:actor',
        label: dic.searchHeader.awg_actor_character
    });
    return options;
};
const createExtraConditionEntry = (): ExtraSearchEntry => ({
    joiner: 'and',
    property: '',
    type: 'in',
    text: '',
});
const createSortByOptions = (dictionary: any) => {
    const options = [
        // value: "resource_class:vocablary_prefx:vocabulary_local_name[@used-as]:with_language
        {
            value: 'TheatricalResource:dcepk:titleTranscription:1',
            className: 'title',
            label: dictionary.item.label.title,
        },
        {
            value: 'Agent:dcepk:agentTranscription@dcepk:applicantAgent:1',
            className: 'group',
            label: dictionary.item.label.company,
        },
        {
            value: 'TheatricalResource:dcepk:creatorNameforDisplayTranscription:1',
            className: 'performer',
            label: dictionary.item.label.performer,
        },
        {
            value: 'TheatricalResource:dcepk:performanceVenueTranscription:1',
            className: 'theater',
            label: dictionary.item.label.theater,
        },
        {
            value: 'TheatricalResource:dcterms:date:0',
            className: 'year',
            label: dictionary.item.label.year,
        },
    ];

    return options;
};

const createSearchCondition = (searchType: RecordsetType = 'overview'): SearchCondition => ({
    searchType,
    fulltext_search: '',
    dcepk__epadCategory: [],
    dcterms__date: [dcterms__date_0, dcterms__date_1],
    dcepk__award: '',
    dcepk__youTubeContentID: '',
    dcepk__materialType: '',
    extra: [createExtraConditionEntry()],
    specifiedKeyword: '',
    specifiedEpadCategory: '',
    specifiedMaterialType: '',
    specifiedYouTubeContentID: '',
    specifiedAward: '',
});
export const searchCondition = reactive<SearchCondition>(createSearchCondition());

export const extraTypeOptions = computed(() => {
    return createExtraTypeOptions(dictionary.value, language.value);
});

export const epadOptions = computed(() => {
    const options = [
        dictionary.value.searchHeader.detail_category_drama,
        dictionary.value.searchHeader.detail_category_dance,
        dictionary.value.searchHeader.detail_category_tradition,
    ];
    return options.map((i) => ({ value: i, label: i }));
});

export const materialTypeOptions = computed(() => {
    const options = [
        dictionary.value.resultHeader.select.stage,
        dictionary.value.resultHeader.select.flyer,
        dictionary.value.resultHeader.select.poster,
    ];
    return options.map((i) => ({ value: i, label: i }));
});

export const sortByOptions = computed(() => {
    return createSortByOptions(dictionary.value);
});
export const extraConditions = computed(() => {
    return searchCondition.extra.filter((ex: ExtraSearchEntry) =>
        ex.text.match(/\S/)
    );
});
export const dateCondition = computed<[string, string]>(() => {
    const dcterms__date = [...searchCondition.dcterms__date];
    if (
        dcterms__date &&
        dcterms__date[0] === dcterms__date_0 &&
        dcterms__date[1] === dcterms__date_1
    ) {
        dcterms__date[0] = '';
        dcterms__date[1] = '';
    }
    return dcterms__date as [string, string];
});

export const requestCondition = (): EnpakuSearchCondition => {
    const {
        specifiedEpadCategory,
        specifiedMaterialType,
        specifiedYouTubeContentID,
        specifiedAward,
        ...rest
    } = searchCondition;

    const requestCondition = {
        ...rest,
        dcepk__epadCategory: [...searchCondition.dcepk__epadCategory],
        dcterms__date: dateCondition.value,
        extra: extraConditions.value.map((i: any) => ({ ...i })),
    };

    if (requestCondition.searchType === 'video') {
        requestCondition.dcepk__youTubeContentID = '1';
    }

    if (specifiedEpadCategory) {
        requestCondition.dcepk__epadCategory =
            requestCondition.dcepk__epadCategory.length === 0 ||
                requestCondition.dcepk__epadCategory.some(
                    (v: string) => v === specifiedEpadCategory
                )
                ? [specifiedEpadCategory]
                : ['NoMatch'];
    }
    if (specifiedMaterialType) {
        requestCondition.dcepk__materialType = specifiedMaterialType;
    }
    if (specifiedYouTubeContentID) {
        requestCondition.dcepk__youTubeContentID = '1';
    }
    if (specifiedAward) {
        requestCondition.dcepk__award = '1';
    }

    return requestCondition;
};

export const resultTitle = computed(() => {
    const responseHasCondition = 0 < Object.keys(getConditionOutline()).length;

    let titleType: RecordsetType | undefined = undefined;
    switch (state.loadingState) {
        case LoadingState.notLoading:
            titleType = state.resultType;
            break;
        case LoadingState.searching:
        case LoadingState.additionalSearching:
        case LoadingState.pagingOrSorting:
            titleType = searchCondition.searchType;
            break;
    }
    switch (titleType) {
        case 'overview':
            return !responseHasCondition
                ? dictionary.value.resultHeader.title.search
                : dictionary.value.resultHeader.title.result;
        case 'image':
            return !responseHasCondition
                ? dictionary.value.resultHeader.title.search
                : dictionary.value.resultHeader.title.resultImages;
        case 'video':
            return !responseHasCondition
                ? dictionary.value.resultHeader.title.search
                : dictionary.value.resultHeader.title.resultVideos;
        default:
            return '';
    }
});

export const totalRecords = computed(() => {
    const searching = [LoadingState.searching, LoadingState.additionalSearching].some(
        (i) => i === state.loadingState
    );
    return searching ? '...' : state.totalRecords;
});

export const addExtraEntry = () => searchCondition.extra.push(createExtraConditionEntry());
export const removeExtraEntry = (index: number) => searchCondition.extra.splice(index, 1);
export const setSearchType = (searchType: RecordsetType) => searchCondition.searchType = searchType;


type SaveData = Pick<typeof state, 'condition' | 'page' | 'sortBy' | 'sortOrder' | 'resultType'> & {
    searchCondition: SearchCondition;
};

const saveStateSessionKey = 'theatreListComposition.fetchRecordset';

const saveAsLastRequest = () => {
    const data: SaveData = {
        condition: state.condition,
        page: state.page,
        sortBy: state.sortBy,
        sortOrder: state.sortOrder,
        resultType: state.resultType,
        searchCondition
    };
    storage.setItem(saveStateSessionKey, JSON.stringify(data));
}
const loadLastRequest = () => {
    const expectJsonString = storage.getItem(saveStateSessionKey);
    let data: SaveData | undefined = undefined;
    if (expectJsonString) {
        try {
            data = JSON.parse(expectJsonString);
        } catch (e) {
            // nothing to do
        }
    }
    if (data) {
        const { resultType, condition, page, sortBy, sortOrder, searchCondition: storedCondition } = data;
        state.condition = condition;
        state.page = page;
        state.sortBy = sortBy;
        state.sortOrder = sortOrder;
        state.resultType = resultType;
        if (storedCondition) {
            applyState(searchCondition, storedCondition);
        }
    }
}
export const retrieveState = async () => {
    loadLastRequest();
    await fetchRecordset();
};


export default state;
