var selectors = require('./selectors');
const lottie = require('lottie-web');

// header icon
const activateHeaderLogo = (container = document.getElementById('js-headerLogo')) => {
    var params3 = {
        container,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/json/header-icon.json',
    };
    var anim3;
    anim3 = lottie.loadAnimation(params3);
    goToRandomAndPlay(anim3);

    $(document).on('click', selectors.menuBtn, function (e) {
        if ($(selectors.body).hasClass('is-menu-open')) {
            goToRandomAndPlay(anim3);
        }
    });
};

// menu icon
const activateMenuLogoMark = (container = document.getElementById('js-menuLogoMark')) => {
    var params4 = {
        container,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/json/header-icon.json',
    };
    var anim4;
    anim4 = lottie.loadAnimation(params4);
    goToRandomAndPlay(anim4);

    $(document).on('click', selectors.menuBtn, function (e) {
        if (!$(selectors.body).hasClass('is-menu-open')) {
            goToRandomAndPlay(anim4);
        }
    });

    $(document).on('click', selectors.menuToggleKeyword, function (e) {
        if ($(selectors.body).hasClass('is-keyword-hidden')) {
            goToRandomAndPlay(anim4);
        }
    });
};

// menuPosts icon
const activateMenuPostsLogo = (container = document.getElementById('js-menuPostsLogo')) => {
    var params5 = {
        container,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: '/assets/json/header-icon.json',
    };
    var anim5;
    anim5 = lottie.loadAnimation(params5);
    goToRandomAndPlay(anim5);

    $(document).on('click', selectors.menuBtn, function (e) {
        if (!$(selectors.body).hasClass('is-menu-open')) {
            goToRandomAndPlay(anim5);
        }
    });

    $(document).on('click', selectors.menuToggleKeyword, function (e) {
        if ($(selectors.body).hasClass('is-keyword-hidden') && $(selectors.body).hasClass('p-home')) {
            goToRandomAndPlay(anim5);
        }
    });
};

async function goToRandomAndPlay(AnimationItem) {
    var start = Math.floor(Math.random() * 7) * 13 * 60;
    AnimationItem.goToAndPlay(start, true);
}

module.exports = {
    activateHeaderLogo,
    activateMenuLogoMark,
    activateMenuPostsLogo
};
