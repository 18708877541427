import { createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "c-pagination"
}
const _hoisted_2 = {
  key: 1,
  class: "c-pagination__dot"
}
const _hoisted_3 = {
  key: 2,
  class: "c-pagination__dot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (1 < _ctx.lastPage)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("a", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleChangePage(_ctx.currentPage - 1))),
          class: ["c-pagination__prev", 1 < _ctx.currentPage ? '' : 'is-hidden'],
          href: "#"
        }, null, 2),
        (1 < _ctx.pageset[0])
          ? (_openBlock(), _createBlock("a", {
              key: 0,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleChangePage(1))),
              class: "c-pagination__number",
              href: "#"
            }, _toDisplayString(1)))
          : _createCommentVNode("", true),
        (2 < _ctx.pageset[0])
          ? (_openBlock(), _createBlock("span", _hoisted_2, "..."))
          : _createCommentVNode("", true),
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.pageset, (p) => {
          return (_openBlock(), _createBlock("a", {
            key: p,
            onClick: ($event: any) => (_ctx.handleChangePage(p)),
            class: ["c-pagination__number", p == _ctx.currentPage ? 'is-active' : ''],
            href: "#"
          }, _toDisplayString(p), 11, ["onClick"]))
        }), 128)),
        (_ctx.pageset[_ctx.pageset.length - 1] < _ctx.lastPage - 1)
          ? (_openBlock(), _createBlock("span", _hoisted_3, "..."))
          : _createCommentVNode("", true),
        (_ctx.pageset[_ctx.pageset.length - 1] < _ctx.lastPage)
          ? (_openBlock(), _createBlock("a", {
              key: 3,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleChangePage(_ctx.lastPage))),
              class: "c-pagination__number",
              href: "#"
            }, _toDisplayString(_ctx.lastPage), 1))
          : _createCommentVNode("", true),
        _createVNode("a", {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleChangePage(_ctx.currentPage + 1))),
          class: ["c-pagination__next", _ctx.currentPage < _ctx.lastPage ? '' : 'is-hidden'],
          href: "#"
        }, null, 2)
      ]))
    : _createCommentVNode("", true)
}