
import { defineComponent, PropType } from "vue";
import { SearchKeyword } from "@/enpaku";

export default defineComponent({
  name: "MenuKeywords",
  props: {
    menuKeywords: Array as PropType<SearchKeyword[]>,
  }
});
