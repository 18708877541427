function attachSelectWithFix() {
  (function($) {
    var marginRight = 2;
    $(document).on('change', '.js-select', function(e) {
      var text = $(this).find(':selected').text();
      var $dummy =  $('<span>').html(text);
      $dummy.appendTo('body');
      var width = $dummy.width();
      $dummy.remove();
      $(this).closest('.c-select').width(width + marginRight);
    });
  })(jQuery);
}

module.exports = attachSelectWithFix;