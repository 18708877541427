require('intersection-observer');

/** ヘッダーの背景色を変える */
function changeHeaderBg(entries) {
  if (!entries[0].isIntersecting) {
    $('#js-header').addClass('l-header--bgWhite');
  } else {
    $('#js-header').removeClass('l-header--bgWhite');
  }
}

/** IntersectionObserverオブジェクトを生成 */
function stickifyListHeader() {
  // スクロールで検知する要素
  var $resultLabel = $('#js-resultHeader')[0];
  // IntersectionObserver のオプション
  var options = {
    root: null,
    rootMargin: '-55px 0px 0px',
    threshold: 0
  };
  // IntersectionObserverオブジェクトの生成
  var observer = new IntersectionObserver(changeHeaderBg, options);
  // observer を監視
  observer.observe($resultLabel);
}

module.exports = stickifyListHeader;
