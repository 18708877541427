import { computed, reactive, ref } from 'vue';
import { Announcement } from '@/enpaku';
import { language } from '@/compositions/pageComposition';
import { LoadingState, LoadingStateType } from './LoadingState';
import fetchAnnouncementRecordset from '@/api/fetchAnnouncementRecordset';
import * as fontplus from '@/fontplus';

interface AnnouncementListState {
    loading: boolean;
    error: string | undefined;
    recordset: Announcement[];
    totalRecords: number;
    loadingState: LoadingStateType;
    currentRow: Announcement | undefined;
}

const state = reactive<AnnouncementListState>({
    loading: false,
    recordset: [],
    totalRecords: 0,
    error: undefined,
    loadingState: LoadingState.notLoading,
    currentRow: undefined,
});

export const loading = computed(() => state.loading);
export const error = computed(() => state.error);
export const currentRow = computed<Announcement>(() => state.currentRow ?? emptyRow());
export const latestNews = computed<Announcement>(
    () => state.recordset.length > 0 ? state.recordset[0] : emptyRow()
);
export const recentNewsRows = computed<Announcement[]>(
    () => state.recordset.length > 0 ? [...state.recordset].slice(0, 3) : []
);
export const pastNewsRows = computed<Announcement[]>(
    () => state.recordset.length > 3 ? [...state.recordset].slice(3) : []
);

const emptyRow = (): Announcement => ({
    id: -1,
    releaseDate: '',
    lang: '',
    category: '',
    header: '',
    content: '',
});
type FetchRecordsetParam = {
    id?: number;
    loader?: boolean;
}
type Unregisterer = () => void;
const fontplusUnregisterRef = ref<Unregisterer>();
export const fetchRecordset = async (param: FetchRecordsetParam = {}) => {
    const { id, loader } = param;

    state.loading = true;

    const lang = language.value === 'en_US' ? 'en_US' : 'ja';
    try {
        const { totalRecords, recordset } = await fetchAnnouncementRecordset({
            lang,
        });
        state.totalRecords = totalRecords || recordset.length;
        state.recordset = recordset;
        state.currentRow = id
            ? recordset.find((row: Announcement) => row.id == id)
            : recordset.length > 0
                ? recordset[0]
                : emptyRow();

        fontplusUnregisterRef.value && fontplusUnregisterRef.value();
        fontplusUnregisterRef.value = fontplus.registerText(JSON.stringify(recordset));
        await fontplus.loadFontplus('announcementListComposition');

    } catch (error) {
        state.error = error instanceof Error ? error.message : String(error);
    } finally {
        state.loading = false;
    }
};
