
import { epksrc } from "@/helper";
import MenuPostsLogoMark from "@/templates/component/MenuPostsLogoMark.vue";
import { defineComponent } from "vue";
import { setLanguage, language } from "@/compositions/pageComposition";

export default defineComponent({
  name: "MenuPosts",
  components: {
    MenuPostsLogoMark,
  },
  props: {
    asHome: Boolean,
  },
  setup() {
    return {
      setLanguage,
      language,
      epksrc,
    };
  },
});
