import fetchPlaytextRecordset from '@/api/fetchPlaytextRecordset';
import { FetchPlaytextRecordsetParam } from '@/api/interface';
import { dictionary, language } from '@/compositions/pageComposition';
import { Playtext } from '@/enpaku';
import { computed, reactive, ref } from 'vue';
import { LoadingState, LoadingStateType } from './LoadingState';
import { checkIsEmpty } from './util';
import * as fontplus from '@/fontplus';

interface PlaytextSearchCondition {
    search: string;
}
interface PlaytextListState {
    loading: boolean;
    error: string | undefined;
    recordset: Playtext[];
    totalRecords: number;
    condition: PlaytextSearchCondition;
    perPage: number;
    page: undefined | number;
    sortBy: undefined | string;
    sortOrder: undefined | 'asc' | 'desc';
    loadingState: LoadingStateType;
}

export const state = reactive<PlaytextListState>({
    perPage: 50,
    page: undefined,
    sortBy: 'disallowFreePublicationOnJdta',
    sortOrder: 'desc',

    loading: false,
    recordset: [],
    totalRecords: 0,
    error: undefined,

    condition: {
        search: '', // Like 検索
    },
    loadingState: LoadingState.notLoading,
});

export const searchCondition = reactive<PlaytextSearchCondition>({
    search: '',
});

export const loading = computed(() => state.loading);
export const error = computed(() => state.error);
export const recordset = computed(() => state.recordset);
export const totalRecords = computed(() => state.totalRecords); // !!!
export const perPage = computed(() => state.perPage); // !!!
export const condition = computed(() => state.condition); // !!!
export const sortBy = computed(() => state.sortBy); // !!!
export const hasCondition = computed(() => !checkIsEmpty(state.condition));

export const sortByOptions = computed(() => {
    return createSortByOptions(dictionary.value, language.value);
});
const createSortByOptions = (dictionary: any, language: string) => {
    const inJapanese = language !== 'en_US';

    const options = [
        {
            value: 'identifier',
            className: 'id',
            label: dictionary.playtext.label.id,
        },
        {
            value: inJapanese
                ? 'titleJapaneseKana:titleJapanese'
                : 'titleEnglish',
            className: 'title',
            label: dictionary.playtext.label.title,
        },
        {
            value: inJapanese
                ? 'authorJapaneseKana:authorJapanese'
                : 'authorEnglish',
            className: 'author',
            label: dictionary.playtext.label.author,
        },
        {
            value: 'yearIssued',
            className: 'yearIssued',
            label: dictionary.playtext.label.yearIssued,
        },
        {
            value: inJapanese
                ? 'numberOfPerformersSort:numberOfPerformersJapanese'
                : 'numberOfPerformersSort:numberOfPerformersEnglish',
            className: 'people',
            label: dictionary.playtext.label.people,
        },
    ];

    return options;
};

export const currentPage = computed<number>(() => state.page || 1);
export const lastPage = computed<number>(
    () => Math.ceil(state.totalRecords / state.perPage) || 1
);
export const pageset = computed<number[]>(() => {
    if (!state.totalRecords || !state.page) return [];

    const aside = 2;
    const prevs =
        aside + Math.max(currentPage.value + aside - lastPage.value, 0);
    const begin = Math.max(currentPage.value - prevs, 1);
    const last = Math.min(begin + aside * 2, lastPage.value);

    // const set = 5;
    // const begin = currentPage.value - ((currentPage.value - 1) % set);
    // const last = Math.min(begin + (set - 1), lastPage.value);

    const pageset = new Array(last - begin + 1)
        .fill(begin)
        .map((v, i) => v + i);
    return pageset;
});

type Unregisterer = () => void;
const fontplusUnregisterRef = ref<Unregisterer>();
export const fetchRecordset = async (
    payload: Partial<FetchPlaytextRecordsetParam> = {}
) => {
    const {
        condition = {
            search: '',
        },
        page = 1,
        sortBy = state.sortBy,
        sortOrder = state.sortOrder,
    } = payload;

    state.loading = true;

    const lang = language.value === 'en_US' ? 'en_US' : 'ja';

    try {
        const { totalRecords, recordset } = await fetchPlaytextRecordset({
            condition,
            page,
            sortBy,
            sortOrder,
            perPage: state.perPage,
            lang,
        });

        state.condition = condition;
        state.totalRecords = totalRecords;
        state.sortBy = sortBy;
        state.sortOrder = sortOrder;
        state.page = page;
        state.recordset = recordset;

        fontplusUnregisterRef.value && fontplusUnregisterRef.value();
        fontplusUnregisterRef.value = fontplus.registerText(JSON.stringify(recordset));
        await fontplus.loadFontplus('playtextListComposition');

    } catch (error) {
        state.error = error;
    } finally {
        state.loading = false;
    }
};

export const setPage = async (page: number) => {
    const { condition, sortBy, sortOrder } = state;
    state.loadingState = LoadingState.pagingOrSorting;
    await fetchRecordset({
        condition,
        page,
        sortBy,
        sortOrder,
    });
    state.loadingState = LoadingState.notLoading;
};

export const toggleSortOrder = async ({
    sortBy,
    sortOrder: specifiedOrder,
}: Partial<typeof state>) => {
    state.loadingState = LoadingState.pagingOrSorting;
    const {
        condition,
        sortBy: currentSortBy,
        sortOrder: currentSortOrder,
    } = state;

    const sortOrder =
        specifiedOrder || sortBy !== currentSortBy
            ? 'asc'
            : currentSortOrder === 'asc'
                ? 'desc'
                : 'asc';

    await fetchRecordset({
        condition,
        page: 1,
        sortBy,
        sortOrder,
    });
    state.loadingState = LoadingState.notLoading;
};

/**
 * メインテキスト検索のEnter
 */
export const search = async () => {
    state.loadingState = LoadingState.searching;
    await fetchRecordset({
        condition: requestCondition(),
        page: 1,
    });
    state.loadingState = LoadingState.notLoading;
};

const requestCondition = (): PlaytextSearchCondition => {
    const { search } = searchCondition;
    return { search };
};

export default state;
