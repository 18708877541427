const windowSize = require('./windowSize');

(function($){

  var observing = false;

  const onResized = function() {
    $('.js-multilineAccordionToggle p').css('-webkit-line-clamp', 'unset');

    var lineClampVal = windowSize.is('useMdLines') ? '5' : '3';
    $('.c-multilineAccordion__toggle p').css('-webkit-line-clamp', lineClampVal);

    $multilineAccordion = $('.js-multilineAccordion');
    if ( $multilineAccordion.hasClass('is-open')) {
      var parentPaddingBottom = parseInt($multilineAccordion.css('padding-bottom').replace('px', ''));
      var $contents = $multilineAccordion.children('.js-multilineAccordionContent');
      var contentsHeight = $contents.outerHeight() + parentPaddingBottom;
      $multilineAccordion.css('height', contentsHeight);
    }
  }

  $.fn.multilineAccordion = function () {
    if(!observing) {
      observing = true;
      windowSize.onResized(() => onResized());
    }
    return this.each(function(){

      if(!this.classList.contains('js-multilineAccordion')) return this;

      var t = this.previousElementSibling

      var $contents = $(t).children('.js-multilineAccordionContent');
      var contentsHeight = $contents.outerHeight();
      var parentHeight = $(t).outerHeight();
  
      if ( contentsHeight < parentHeight ) {
        $(t).addClass('is-disabled');
        this.style.display = "none"
        return this;
      }

      $(this).on('click', function(ev){
        if ( $(t).hasClass('is-open') ) {
          $(t).css('height', '').removeClass('is-open');
        } else {
          var parentPaddingBottom = parseInt($(t).css('padding-bottom').replace('px', ''));
          var contentsHeight = $contents.outerHeight() + parentPaddingBottom;
          $(t).css('height', contentsHeight).addClass('is-open');
        }
      });
    });
  }
})(jQuery);
