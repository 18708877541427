require('smoothscroll-polyfill').polyfill();
const selectors = require('./selectors');
const windowSize = require('./windowSize');

function attachWindowControlAndAnchorScroll() {
  (function ($) {
    var $window = $(window),
      $header = $(selectors.header);
    
    // Note: $(window).width(); があると、なぜか変な動きをする。
    
    var windowW = null,
      windowH = $window.outerHeight(),
      headerHeight = $header.outerHeight(),
      isInit = true;

    // anchor link
    function scrollToAnker(hash) {
      var target = $(hash);
      if (target.length) {
        var offset = windowSize.is('useMdOffset') ? headerHeight : 30;
        var position = target.offset().top - offset;
        $('body,html').stop().animate({ scrollTop: position }, 400);
      }
    }

    function anchorLink() {
      // 遷移時にページ内リンクだったとき
      var urlHash = location.hash;
      if (urlHash && urlHash.indexOf('#&') == -1) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function () {
          //ロード時の処理を待ち、時間差でスクロール実行
          scrollToAnker(urlHash);
        }, 100);
      }

      $(document).on('click', 'a[href^="#"]', function (e) {
        e.preventDefault();
        var href = $(this).attr('href');
        if (href == "#" || href == "") {
          return;
        }
        scrollToAnker(href);

        return false;
      });
    }

    function changeHeight() {
      windowH = $window.outerHeight(),
        headerHeight = $header.outerHeight();
      var newWindowW = $window.width();

      const isHome = $(selectors.body).hasClass('p-home');
      if (newWindowW !== windowW || isInit || isHome) {
        if (newWindowW <= 750 ) {
          $('.js-100vh').css('height', windowH);
        } else {
          $('.js-100vh').css('height', '');
        }
        windowW = $window.width();
        isInit = false;
      }
    }

    function detectTouchDevice() {
      if (window.ontouchstart === null) {
        $('body').addClass('is-touch');
        isTouchDevice = true;
      }
    }

    $(function () {
      changeHeight();
      detectTouchDevice();
      anchorLink();
      windowSize.onResized(() => {
        windowW = $window.width(),
        windowH = $window.outerHeight();
        changeHeight();
      });
    });
  })(jQuery);
}

module.exports = attachWindowControlAndAnchorScroll;