
export const checkIsEmpty = (value: any): boolean => {
    switch (typeof value) {
        case 'undefined': return true;
        case 'object':
            if (null === value) return true;
            return typeof value.length !== 'undefined'
                ? !value.some((v: any) => !checkIsEmpty(v))
                : Object.values(value).reduce<boolean>(
                    (isEmpty, i) => isEmpty || checkIsEmpty(i),
                    false
                );
        case 'string':
            return value.replace(/\s/g, '').length === 0;
        case 'number':
            return isNaN(value);
        case 'boolean':
            return !value;
        default:
            return false;
    }
};

export const conditionOutline = (condition: any) => {
    if (!condition) return {};
    const outline: Record<string, any> = Object.entries(condition).reduce(
        (collection, [key, value]) => {
            return checkIsEmpty(value) ? collection : { ...collection, [key]: value }
        },
        {}
    );
    return outline;
};

export const applyState = <T>(state: T, apply: Partial<T>) => {
    Object.entries(apply).forEach(([k, v]) => {
        if (k in state) (state as any)[k] = v;
    });
}

export const replaceArray = <T extends any[]>(state: T, apply: T) => {
    state.splice(0, state.length);
    apply.forEach((i) => state.push(i));
}