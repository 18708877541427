import { site } from '@/util/config';
import { apiGet } from './apiGet';
import { FetchKeywords } from './interface';

const endpoint = site.apiEndpoints.randomKeywords;

export const fetchKeywords: FetchKeywords = async ({ lang }) => {
    const { headers, data } = await apiGet(endpoint, {
        lang: lang || 'ja',
        r: (new Date()).getTime(),
    });
    const totalRecords =
        'jdta-total-results' in headers
            ? Number(headers['jdta-total-results'])
            : data.length;

    return { totalRecords, recordset: data };
};
