import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "l-main",
  id: "main"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")
  const _component_Header = _resolveComponent("Header")
  const _component_Menu = _resolveComponent("Menu")
  const _component_Footer = _resolveComponent("Footer")
  const _component_PhotoSwipeDialog = _resolveComponent("PhotoSwipeDialog")
  const _component_Loader = _resolveComponent("Loader")

  return (_openBlock(), _createBlock(_Fragment, null, [
    (!!_ctx.globalError)
      ? (_openBlock(), _createBlock(_component_Error, {
          key: 0,
          code: _ctx.globalError.code,
          message: _ctx.globalError.message
        }, null, 8, ["code", "message"]))
      : (_openBlock(), _createBlock("div", {
          key: 1,
          id: "body",
          class: _ctx.className
        }, [
          _createVNode("div", {
            id: "js-wrapper",
            class: ["l-wrapper", { 'js-100vh': _ctx.splash }]
          }, [
            (!_ctx.noHeader)
              ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
              : _createCommentVNode("", true),
            _createVNode(_component_Menu, {
              homeMenuLogo: _ctx.homeMenuLogo,
              splash: _ctx.splash,
              asHome: _ctx.asHome
            }, null, 8, ["homeMenuLogo", "splash", "asHome"]),
            _createVNode("main", _hoisted_1, [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _createVNode(_component_Footer)
          ], 2),
          _createVNode(_component_PhotoSwipeDialog)
        ], 2)),
    _createVNode(_component_Loader, {
      class: { is_show: _ctx.loader }
    }, null, 8, ["class"])
  ], 64))
}