var selectors = require('./selectors');
var splash = require('./splash');

/*
メニュー状態

(A) 閉じた状態
  - ランダム切替 X

(B) 開いた状態 (タイトル文・メニュー表示)
  - ランダム切替 O

(C) 開いた状態 (キーワードから探す/キーワード未設定)
  - ランダム切替 O

(D) 開いた状態 (キーワードから探す/キーワード設定)
  - ランダム切替 X
*/
let autoplay = false;
let next = undefined;
let windowTop = 0;

const setupLayoutInitializer = (function ($) {
  return (adapter) => {

    async function randomSwap() {
      const [{ index, item }] = await Promise.all([next, wait(1500)]);
      next = adapter.prepareRandomSwap();
      if (-1 < index && item) {
        await turnToHideItem(index);
        renderItem(item, index);
        await turnToShowItem(index);
      }
    }

    async function startRandomSwap() {
      if (autoplay) return;
      next = adapter.prepareRandomSwap();
      autoplay = true;
      while (autoplay) {
        await randomSwap();
      }
    }

    async function stopRandomSwap() {
      if (!autoplay) return;
      autoplay = false;
    }

    // 初回表示用のサムネイル画像を取得
    const postsInitialized = adapter.preparePosts();

    const attachHandlers = () => {
      $(document).on('mouseenter', selectors.menuPosts, function () {
        $(this).addClass('is-darken');
      });
      $(document).on('mouseleave', selectors.menuPosts, function () {
        $(this).removeClass('is-darken');
      });
      $(document).on('touchstart', selectors.menuPostItem, function () {
        $(selectors.menuPosts).addClass('is-darken');
        $(this).addClass('hover');
      })
      $(document).on('touchend', selectors.menuPostItem, function () {
        $(selectors.menuPosts).removeClass('is-darken');
        $(this).removeClass('hover');
      })
      $(document).on('click', selectors.menuToggleKeyword, function (e) {
        e.preventDefault();
        const $body = $(selectors.body);

        // home でハンバーガーメニューを開いた状態で close アイコンをクリックした場合
        if ($body.hasClass('is-menu-visible') && $(e.target).hasClass('c-menuPosts__close')) {
          $body.removeClass('is-menu-visible');
          return false;
        }

        if ($body.hasClass('is-keyword-hidden')) {
          $body.addClass('is-keyword-visible');
          $body.removeClass('is-keyword-hidden');
          $body.removeClass('is-menu-visible');
          // stopRandomSwap();
        } else {
          $body.removeClass('is-keyword-visible');
          $body.addClass('is-keyword-hidden');
          // キーワードのアクティブ解除
          flushKeywords();
          // ランダム切り替えを再開
          startRandomSwap();
        }
        return false;
      });
      $(document).on('click', selectors.keywords, function (e) {
        e.preventDefault();
        var keywordElm = e.currentTarget;
        if ($(keywordElm).hasClass('is-active')) {
          flushKeywords();
          // ランダム切り替えを再開
          startRandomSwap();
        } else {
          setKeyword(keywordElm);
        }
        return false;
      });
      $(document).on('click', selectors.menuPostsBtn, function (e) {
        e.preventDefault();
        const $body = $(selectors.body);
        $body.toggleClass('is-menu-visible');
        return false;
      });
      $(document).on('click', selectors.menuBtn, function (e) {
        e.preventDefault();
        toggleMenu();
        return false;
      });
    };

    function toggleMenu() {
      const $body = $(selectors.body);
      if ($body.hasClass('is-menu-open')) {
        closeMenu();
      } else {
        openMenu();
      }
    }

    function openMenu() {
      const $body = $(selectors.body);
      windowTop = $(window).scrollTop();
      $body.css({'top': '-' + windowTop + 'px', 'position': 'fixed', 'width': '100%' });
      $('body').css({'overflow': 'hidden'});
      $body.addClass('is-menu-open');
      startRandomSwap();
    }

    function closeMenu() {
      const $body = $(selectors.body);
      $body.css({'top': '', 'position': '', 'width': '' });
      $('body').css({'overflow': ''});
      $(window).scrollTop(windowTop);
      $body.removeClass('is-menu-open');

      // ランダム切り替えを停止
      stopRandomSwap();
      setTimeout(function () {
        $(selectors.keywords).removeClass('is-active');
        adapter.clearSearchResults();
        renderAll();
      }, 300);
    }

    async function setKeyword(keywordElm) {
      // ランダム切り替えを停止
      stopRandomSwap();

      // アクティブ切り替え
      const $keywords = $(selectors.keywords);
      $keywords.removeClass('is-active');
      $(keywordElm).addClass('is-active');

      // 対象データを取得
      const selectedWord = $(keywordElm).attr('data-txt');
      await Promise.all([
        adapter.prepareSearchResult(selectedWord),
        turnToHidePosts()
      ]);
      await renderAll();
      await turnToShowPosts();
    }

    async function flushKeywords() {
      // キーワードのアクティブ解除
      $(selectors.keywords).removeClass('is-active');

      // 暗転 > データ入れ替え > 表示
      await turnToHidePosts();
      adapter.clearSearchResults();
      await renderAll();
      await turnToShowPosts();
    }

    async function renderAll() {
      await postsInitialized;
      const $menuPosts = $(selectors.menuPosts);
      $menuPosts.empty();
      const display = adapter.getDisplay();
      display.forEach((post) => $menuPosts.append(buildItemHtml(post)));
    }

    async function renderItem(post, index) {
      const $menuPosts = $(selectors.menuPosts);
      const $child = $menuPosts.children().eq(index);
      if (0 < $child.length) {
        $child.empty().append(buildItemHtml(post, true, false));
      } else {
        $menuPosts.append(buildItemHtml(post, true));
      }
    }

    const initializeTopPage = async (options = { disableSplash: false }) => {
      attachHandlers();
      const waits = [];
      if (options.disableSplash) {
        waits.push(splash.skip());
      } else {
        waits.push(splash());
      }
      waits.push(renderAll());

      await waits;
      await turnToShowPosts();
      startRandomSwap();
    };

    const initializeSubPage = async (options = {}) => {
      attachHandlers();
      await renderAll();
      await turnToShowPosts();
    };

    return { initializeTopPage, initializeSubPage };
  };
})(jQuery);

const wait = (ms) => new Promise(resolve => setTimeout(() => resolve(), ms));
const waitTransition = ($elem) => new Promise(resolve => {
  const off = () => {
    $elem.off('transitionend webkitTransitionEnd', off);
    resolve();
  };
  $elem.on('transitionend webkitTransitionEnd', off);
});


/**
 * 全サムネイルをフェードアウト
 * @returns {Promise<void>}
 */
async function turnToHidePosts() {
  const $menuPosts = $(selectors.menuPosts);
  if (!$menuPosts.hasClass('is-hidden')) {
    $menuPosts.addClass('is-hidden');
    await waitTransition($menuPosts);
  }
}

/**
 * 全サムネイルをフェードイン(表示の準備完了)
 * @returns {Promise<void>}
 */
async function turnToShowPosts() {
  const $menuPosts = $(selectors.menuPosts);
  if ($menuPosts.hasClass('is-hidden')) {
    $menuPosts.removeClass('is-hidden');
    await waitTransition($menuPosts);
  }
}

/**
 * 特定サムネイルをフェードアウト
 * @param {number} index
 * @returns {Promise<void>} 
 */
async function turnToHideItem(index) {
  const $parent = $(selectors.menuPosts).children().eq(index);
  if (0 < $parent.length && !$parent.hasClass('is-hidden')) {
    $parent.addClass('is-hidden');
    await waitTransition($parent);
  }
}

/**
 * 特定サムネイルをフェードイン
 * @param {number} index
 * @returns {Promise<void>} 
 */
async function turnToShowItem(index) {
  const $parent = $(selectors.menuPosts).children().eq(index);
  if (0 < $parent.length && $parent.hasClass('is-hidden')) {
    $parent.removeClass('is-hidden');
    await waitTransition($parent);
  }
}


const buildItemHtml = (post, hidden = false, wrap = true) => `
  ${wrap ? `<div class="c-item_kw ${hidden ? 'is-hidden' : ''}">` : ''}
  <a class="c-item_kw__link js-itemKeyword"
    href="${post.path}"
    data-id="${post.id}"
  ><span
    class="c-item_kw__img"
    style="background-image: url(${post.image});"></span
  ><span class="c-item_kw__title">${post.title}<span class="c-item_kw__company">${post.applicantAgent}</span
  ></span
  ></a>
  ${wrap ? '</div>' : ''}
`;


module.exports = setupLayoutInitializer;
