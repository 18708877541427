
import { defineComponent } from "vue";
import { epksrc } from "@/helper";
import { site } from "@/util/config";

export default defineComponent({
  name: "HomeMenuLogo",
  setup() {
    return {
      siteName: site.siteName,
      epksrc,
    };
  },
});
