
import { computed, defineComponent } from "vue";

import Header from "@/templates/layout/Header.vue";
import Menu from "@/templates/component/Menu.vue";
import Error from "@/templates/layout/Error.vue";
import Loader from "@/templates/layout/Loader.vue";
import Footer from "@/templates/layout/Footer.vue";
import PhotoSwipeDialog from "@/templates/component/PhotoSwipeDialog.vue";
import fontplus from "@/fontplus";
import {
  language,
  loader,
  globalError,
} from "@/compositions/pageComposition";

export default defineComponent({
  name: "Layout",
  components: {
    Header,
    Menu,
    Footer,
    PhotoSwipeDialog,
    Loader,
    Error,
  },
  props: {
    splash: Boolean,
    homeMenuLogo: Boolean,
    bodyClass: String,
    noHeader: Boolean,
    asHome: Boolean,
  },
  setup(props) {
    fontplus("Layout");
    const className = computed(() => {
      const classItems: string[] = [
        props.bodyClass ? props.bodyClass : "is-keyword-hidden",
      ];
      if (language.value === "ja_JP") {
        classItems.push("lang-ja");
      } else {
        classItems.push("lang-en");
      }

      if (loader.value) {
        classItems.push("loading");
      }
      return classItems.join(" ");
    });
    return { className, loader, globalError };
  },
});
