(function ($) {
  $(document).on('mouseenter', '.js-itemList', function () {
    $(this).addClass('hover');
  });
  $(document).on('mouseleave', '.js-itemList', function () {
    $(this).removeClass('hover');
  });

  $(document).on('click', '.js-itemList', function (e) {
    $(this).off('mouseleave');
  });

  $(document).on('touchstart', '.js-hoverItem', function (e) {
    $(e.currentTarget).addClass('hover');
  });
  $(document).on('touchend', '.js-hoverItem', function (e) {
    $(e.currentTarget).removeClass('hover');
  });

})(jQuery);
