import { nextTick, onMounted } from "vue";

const delay = 30;

interface FontLoader {
    start: () => void;
    async: () => void;
    isloading: () => boolean;
    reload: (flag: boolean) => void;
    attachCompleteEvent: (handler: any) => void;
}
const fallbackFontLoader = ({
    async: () => { console.log('fontplus-async') },
    start: () => {
        requestStack.splice(0, requestStack.length).forEach((i) => i());
        console.log('fontplus-start')
    },
    isloading: () => false,
    reload: () => {
        requestStack.splice(0, requestStack.length).forEach((i) => i());
        console.log('fontplus-reload')
    },
    attachCompleteEvent: () => {
        console.log('fontplus-attachCompleteEvent')
    },
});

const getFontLoader = (): FontLoader => (window as any).FONTPLUS ?? fallbackFontLoader;

getFontLoader().attachCompleteEvent(
    () => requestStack.splice(0, requestStack.length).forEach((i) => i())
);

let lastRequestId = 0;
const requestStack: (() => void)[] = [];

let started = false;
export const loadFontplus = (name: string = 'Unknown') => new Promise<void>(resolve => {
    // console.log(name + ' requests reload fonts');
    const id = ++lastRequestId;
    requestStack.push(resolve);
    setTimeout(
        () => {
            if (id === lastRequestId) {
                // console.count('try to reload fonts');
                if (!started) {
                    started = true;
                    getFontLoader().start();
                } else {
                    getFontLoader().reload(false);
                }
            }
        },
        delay
    );
});

export const registerText = (text: string) => {
    const bodyElement = document.querySelector('body');
    if (bodyElement) {
        const dummyToLoadFont = document.createElement('div');
        dummyToLoadFont.style.setProperty('display', 'none');
        dummyToLoadFont.classList.add('fontplus-load');
        dummyToLoadFont.textContent = text;
        bodyElement.appendChild(dummyToLoadFont);
        return () => dummyToLoadFont.parentNode?.removeChild(dummyToLoadFont);
    } else {
        return () => {
            // nothing to do
        };
    }
}

const fontplus = (name: string = 'Unknown') => onMounted(() => nextTick(() => loadFontplus(name)));
// const fontplus = (name: string = 'Unknown') => false;

getFontLoader().async();

export default fontplus;
