import { computed, reactive } from 'vue';
import { SearchKeyword } from '@/enpaku';
import { language } from './pageComposition';
import { fetchKeywords } from '@/api/fetchKeywords';

interface KeywordsState {
    keywords: SearchKeyword[];
}
const state = reactive<KeywordsState>({
    keywords: [],
});

// selectors
export const keywords = computed(() => state.keywords);

export const loadKeywords = async () => {
    const lang = language.value === 'en_US' ? 'en' : 'ja';
    const { recordset } = await fetchKeywords({ lang });
    state.keywords = recordset;
};

export default state;
