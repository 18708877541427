import { Announcement, OmekaTimestamp } from "@/enpaku";
import { site } from '@/util/config';
import axios from 'axios';
import { FetchAnnouncementRecordset } from "./interface";

const endpoint = site.apiEndpoints.announcements;

interface OmekaAnnouncementRecord {
    '@context': string;
    '@id': string;
    '@type': 'o:Announcement';
    'o:id': number;
    'o:lang': string;
    'o:category': string;
    'o:release_date': OmekaTimestamp;
    'o:header': string;
    'o:content': string;
    'o:created': OmekaTimestamp;
    'o:modified': OmekaTimestamp;
}

const fetchAnnouncementRecordset: FetchAnnouncementRecordset = async ({
    lang = 'ja',
}) => {
    const requestPayload = {
        limit: 9999,
        sort_by: 'release_date',
        sort_order: 'desc',
        lang: lang,
    };

    const axiosResponse = await axios({
        method: 'get',
        url: endpoint,
        params: requestPayload,
        responseType: 'json',
    });

    if (axiosResponse.status !== 200) {
        throw new Error(
            'サーバーとの接続に失敗しました ' +
            axiosResponse.status.toString() +
            ': ' +
            axiosResponse.statusText
        );
    }

    const recordset = (axiosResponse.data as OmekaAnnouncementRecord[]).map(
        (r) => regularize(r, lang)
    );

    const totalRecords =
        'jdta-total-results' in axiosResponse.headers
            ? Number(axiosResponse.headers['jdta-total-results'])
            : recordset.length;

    return { totalRecords, recordset };
};

const regularize = (r: OmekaAnnouncementRecord, lang: string): Announcement => {
    return {
        id: r['o:id'],
        releaseDate: formatOmekaTimestamp(r['o:release_date'], lang === 'en_US' ? 'j/n/Y' : 'Y.n.j'),
        lang: r['o:lang'],
        category: r['o:category'],
        header: r['o:header'],
        content: r['o:content'],
    };
};

const formatOmekaTimestamp = (ts: OmekaTimestamp, format: string = 'Y / n / j'): string => {
    if (
        ts && ts.date && ts.date.match(/^(\d+)\D+(\d+)\D+(\d+)\b/)
    ) {
        const [y, m, d] = [Number(RegExp.$1), Number(RegExp.$2), Number(RegExp.$3)];
        if (!y || !m || !d) return '';

        format = format.replace(/Y/g, y.toString());

        format = format.replace(/n/g, m.toString());
        format = format.replace(/m/g, ('00' + m.toString()).substr(-2));

        format = format.replace(/j/g, d.toString());
        format = format.replace(/d/g, ('00' + d.toString()).substr(-2));

        return format;
    } else {
        return '';
    }
};

export default fetchAnnouncementRecordset;
