module.exports = {
    "site": {
        "description": 'Japan Digital Theater Archives (JDTA) is a special website for searching information on stage performances in the three fields of contemporary theater, dance, and traditional performing arts, which was established by the Waseda University Theater Museum (Enpaku) as part of the EPAD.'
    },
    "about": {
        "credits": {
            "company": {
                "enpaku": "The Tsubouchi Memorial Theatre Museum, Waseda University",
                "ndc": "Nippon Design Center, Inc.",
                "nice": "office nice",
                "vectorDesign": "Vector Design Inc.",
            },
            "staff": {
                "team": {
                    "enpaku": "OKAMURO Minako, NOHARA Kanako, MATSUTANI Haruna, SUZUKI Junko, NAKANISHI Tomonori, SAKUMA Satoshi, TAMAKI Nobuko, SAWA Miyako, YAZAWA Daigo / [Supporters] MORI Chiharu, KIZUKURI Nozomi, KUBOKI Yu, NAKAMURA Ryuichi, WADA Satoshi, IWANAGA Yuichi (LeMerlin)",
                    "ndc": "SAITO Hiroyuki, TOMITA Taku, HOSOMI Yuta",
                    "nice": "SHIMIZU Kohei, MIURA Saori, WATANABE Yuichi",
                    "vectorDesign": "UMEZAWA Gen, MANO Kensuke, INOUE Junichi, KANO Masayuki, KIHARA Susumu /  [Supporter] FUJIMOTO Takako",
                },
                "team2": {
                    "enpaku": "OKAMURO Minako, NOHARA Kanako, OKAHARA Akiko, KOMATSU Megumi, TOMISHIGE Satoko, KONISHI Shintaro, YAMADA Nami",
                },
                "team3": {
                    "enpaku": "OKAMURO Minako, WADA Osamu, NOHARA Kanako, OKAHARA Akiko, NAKANISHI Tomonori, WATANABE Masumi, KAWABATA Naho, SHIMIZU Makiko, TAMURA Yumi, MABUCHI Keiko, YANAI Arisa",
                    "cooperate": "[Supporters] OZAKI Rui, HORI Mika, HAYASAKA Aya, KONISHI Shintaro, KISHIMOTO Keiko, Samuel Annesley",
                },
                "specialThanks": {
                    "_01": "SUGIMOTO Shigeo (Professor Emeritus, University of Tsukuba)",
                    "_02": "NAKAMURA Satoru (Assistant Professor, Historiographical Institute, University of Tokyo)",
                    "_03": "FUKUI Kensaku, TAJIMA Yuki (Kotto Dori Law Office)",
                    "enpaku": "KODAMA Ryuichi, GOTO Ryuki, HARADA Masumi, FUJIYA Keiko, SHIBATA Kotaro, HASEGAWA Rie, MIYOSHI Tamaki, SAEGUSA Eiko",
                },
            },
            "title": {
                "specialThanks": "Special Thanks to Those who Cooperated in Opening this Site",
                "team": "Japan Digital Theatre Archives Production Team",
                "team2": "2022 JDTA Staff members",
                "team3": "2023 JDTA Staff members",
            },
        },
        "par01": "The Theatre Museum of Waseda University (referred to as ‘Enpaku’, which is short for ‘theatre museum’ in Japanese) is part of <a class=\"c-text__link c-text__link--external\" href=\"https://db.epad.jp/\" data-txt=\"the larger Emergency Performing Arts Archive + Digital Theatre Support Project (referred to as ‘EPAD’)\" target=\"_blank\"><span>the larger Emergency Performing Arts Archive + Digital Theatre Support Project (referred to as ‘EPAD’)</span></a>, which is sponsored by the Agency of Cultural Affairs. Enpaku has had the privilege, as part of this project, to develop and operate the Japan Digital Theatre Archives (JDTA). This JDTA is a special website to search information related to the video recordings of the Japanese performing arts in three domains, spanning contemporary Japanese theatre, dance and traditional performing arts.",
        "par02": "As everyone is well aware, in 2020, many performances in the performing arts had to be postponed or cancelled due to COVID-19. In addition, because of restrictions (including those on audience size), the number of spectators attending those performances that were actually held dropped dramatically. As a result of these and other COVID-related issues, the arts have suffered significant setbacks. EPAD was adopted as an emergency support project as part of <a class=\"c-text__link c-text__link--external\" href=\"https://www.bunka.go.jp/shinsei_boshu/kobo/92491501.html\" data-txt=\"the Agency for Cultural Affairs’ 2021 Project to Strengthen the Earning Capacity of the Cultural Arts\" target=\"_blank\"><span>the Agency for Cultural Affairs’ 2021 Project to Strengthen the Earning Capacity of the Cultural Arts</span></a>. The project has been consigned to a collaboration between <a class=\"c-text__link c-text__link--external\" href=\"https://www.terrada.co.jp/en/\" data-txt=\"Terada Warehouse Co.,  Ltd\" target=\"_blank\"><span>Terada Warehouse Co.,  Ltd</span></a> and <a class=\"c-text__link c-text__link--external\" href=\"https://www.jpasn.net\" data-txt=\"the Japan Performing Arts Solidarity Network\" target=\"_blank\"><span>the Japan Performing Arts Solidarity Network</span></a>. Its purpose is to support and contribute to the strengthening of the revenue capacity of the performing arts, which have fallen into financial difficulties because of the coronavirus crisis. A defining feature of the EPAD is that it provides support for not only new recordings of stage performances of plays, dance, and traditional performing arts but also the collection and digitisation of extant footage of performances, with the objective of facilitating copyrighting and making this footage available to the public.",
        "par03": "Prior to the JDTA, Enpaku worked for four years on another project that was also sponsored by the Agency for Cultural Affairs as part of its Project to Promote Important Sectors of Art and History Museums. This particular endeavour, which began in fiscal 2014, was named <a class=\"c-text__link c-text__link--external\" href=\"https://www.waseda.jp/prj-stage-film/\" data-txt=\"the Project to Survey and Research the Digital Preservation and Use of Motion Pictures Related to Performing Arts and Entertainment\" target=\"_blank\"><span>the Project to Survey and Research the Digital Preservation and Use of Motion Pictures Related to Performing Arts and Entertainment</span></a>. In 2014 and 2015, we conducted a nationwide survey of theatre groups and cultural facilities – such as theatres, halls, and museums – regarding their holdings of footage related to the performing arts. We learned from the results of this survey that once these recordings were made, many theatre groups and theatres did nothing with them. They were lying idle in storage. We also learned that many recordings were in VHS or other formats that were at risk of deteriorating over time and, therefore, were in urgent need of digitisation in order to properly preserve them. Since that time, I have held the belief that it is Enpaku’s social mission to collect and preserve the precious visual records of performances so as to create a historical record of the performing arts. However, the reality is that the collection and digitisation of such an enormous number of recordings of stage performances would be difficult without resources from the national government, as was the case for films for the National Film Archive of Japan.",
        "par04": "Now, through the EPAD, we have been able to preserve approximately 1,300 videos, as well as fliers and stage photographs, in the Enpaku Digital Archive. Making these valuable artefacts easily accessible to the public is dependent on launching the JDTA. While the JDTA contains information related to digitised works of contemporary theatre, dance, and traditional performing arts, the JDTA also includes a variety of material on plays by long-established companies, works by young playwrights, children’s theatre, musicals, and 2.5D performing arts. There are also an abundance of dance works and a listing of about 550 scripts of plays collected by the EPAD. Scrolling through the JDTA is like viewing a vivid outline of Japan’s performing arts.",
        "par05": "I believe that if the JDTA can make information about these works of art more easily searchable for artists and researchers as well as ordinary fans of the theatre, dance, and traditional performing arts and even people who previously have had little connection to the theatre, familiarity with these works will increase. In doing so, we may be able to expand participation in theatre-going culture, thereby, contributing to the revitalisation of the performing arts, which has suffered so much during the coronavirus crisis, and sustainably increasing the revenue they generate. Moreover, since the JDTA website is in both Japanese and English, it should provide an opportunity for Japan’s wonderful theatre culture to be more widely appreciated overseas.",
        "par06": "In addition, in the future, as video recordings are copyrighted through the EPAD, we hope that they will be made available to the public through video streaming websites. However, most are available for viewing at Enpaku by making a reservation (a three-minute excerpt will also be posted on the site for all copyrighted works).",
        "par07": "Since 1928, when it was founded by Tsubouchi Shoyo, Enpaku has been the only museum in Asia, and one of the few in the world, dedicated solely to the theatre and, as such, the collection, preservation, and display of theatre-related artefacts from all times and places. In addition, Enpaku’s Digital Archive has been open to the public since 2001 and has many accomplishments of its own. In fiscal 2020, in addition to 1,300 performance arts videos and other related artefacts, 550 scripts and related information collected by the Japan Playwrights Association were added to the collection through the EPAD, and this is just the beginning. Nationwide, an enormous number of videos of stage performances lie in storage and have never seen the light of day. If we can take advantage of this opportunity to collect recordings of important stage performances and prevent their loss or neglect, we can ensure that the richness and diversity of the history of Japanese performing arts will be transmitted to posterity. I look forward to the continued cooperation and support of everyone involved in these endeavours.",
        "par08": "Finally, I would like to express my sincere thanks to everyone who has provided us valuable artefacts. It is my sincere hope that the launch of the JDTA will increase the number of people attending plays, dance performances, and performances in the traditional performing arts as well as those viewing them on video streaming websites.",
        "par09": "EPAD 2022 participated in the Art Caravan Project, which was selected by the Agency for Cultural Affairs. This project aims to support the revival of cultural and artistic activities after the Corona Disaster. EPAD curated approximately 400 videos of performing arts and donated them to the Theatre Museum. Building upon a similar initiative in 2020, the Theatre Museum further enriched the Japan Digital Theatre Archives (JDTA) by incorporating information related to the videos, flyers, stage photos, and 3-minute video clips from productions that had undergone rights processing. To watch most of these videos, visitors can visit the AV booth at the Theatre Museum (advance reservations are required) .",
        "par10": "",
        "par11": "",
        "par12": "In 2023, the Theater Museum added new information on 490 performing arts videos to the Japan Digital Theatre Archives (JDTA). These videos were curated by EPAD (Eternal Performing Arts Archives and Digital Theatre), with support from the Agency for Cultural Affairs, Government of Japan, through the Japan Arts Council.",
        "sign": {
            "_01": "February 2021",
            "_02": "February 2023",
            "_03": "Minako Okamuro",
            "_04": "Director , Tsubouchi Memorial Theatre Museum of Waseda University <br>Director , Japan Digital Theatre Archives Production Team",
            "_05": "January 2024",
            "_06": "Ryuichi Kodama",
            "_07": "Director , Tsubouchi Memorial Theatre Museum of Waseda University",
        },
        "title": {
            "credits": "Credits",
        },
    },
    "colon": ":",
    "detail": {
        "annotation" : {
            "multiple": "There are multiple video data related to this performance. Please click \"<\" and \">\" to confirm.",
            "copyright": "The copyright of the image / video data belongs to the author of the work. It may not be duplicated or reprinted without permission.",
            "video": "Photos may include images captured from video."
        },
        "heading": {
            "about": "Abstract of performance/programme",
            "cast": "Cast",
            "company": "Company/Producer information",
            "image": "Still images",
            "info": "Performance information",
            "notes": "Other",
            "program": "Programme title ",
            "relation": "Recommend",
            "staff": "Staff",
            "video": "Videos",
            "isReferencedBy": "About published information",
        },
        "video": {
            "date": "Date of recording",
            "howUse": "Click here for how to use",
            "link": "Video distribution site",
            "number": "Item No.",
            "private": "This video is not available at the Theatre Museum",
            "public": "Appointment required to view this video in the Theatre Museum, Waseda University",
            "rightHolder": "Rights holder",
            "time": "Recording time",
            "web": "Web",
            "distributed": "This video has been processed for rights. Accordingly it has been distributed on an external distribution site or may be distributed in future.",
        },
    },
    "enpaku": "The Tsubouchi Memorial Theatre Museum, Waseda University",
    "enpaku_address": "1-6-1 Nishi-Waseda, Shinjuku-ku, Tokyo 169-8050",
    "item": {
        "label": {
            "award": "Award",
            "company": "Company / Producer",
            "endDate": "Final day",
            "keyword": "Keywords",
            "location": "Location",
            "performer": "Director / Choreographer",
            "sponsor": "Organizer",
            "startDate": "Premiere day",
            "theater": "Venue",
            "theaterOther": " and others",
            "title": "Title of performance",
            "web": "Web site",
            "year": "Year of performance",
            "db": "Waseda University Cultural Resource Database"
        }
    },
    "menu": {
        "home_lead": "This JDTA website is provided to search information related to the video recordings of the Japanese performing arts in three domains, spanning contemporary theatre, dance and traditional performing arts. JDTA is developed and operated by the Theatre Museum of Waseda University, as a part of EPAD .",
    },
    "pageTitle": {
        "about": "About JDTA",
        "detail": "Detail",
        "news": "News",
        "playtext": "Playtext index",
        "privacy": "Privacy Policy",
        "search": "Search",
        "howto": "How to use",
        "terms": "Terms of use",
        "top": "Top",
    },
    "playtext": {
        "description": "List of the Playtexts Collected and Digitized by Emergency Performing Arts Archive ＋ Digital Theatre(EPAD).<br>By consignment from EPAD, the Japan Playwrights Association has collected numerous works from all over the country. On the portal site \"<a class=\"c-text__link c-text__link--external\" href=\"https://playtextdigitalarchive.com/\" data-txt=\"Playtext Digital Archive\" target=\"_blank\"><span>Playtext Digital Archive</span></a>\" produced by the association, you can read and download the playtexts for free, and you can also find out who to apply for a performance license. However, the marked playtexts with “<span class=\"c-text_logo\"></span>” are listed only; they can be viewed in the Theatre Museum.<br>The playtexts in this list were collected in another EPAD project from the stage performance video collection that could be searched on this site.",
        "label": {
            "id": "ID",
            "author": "Playwright",
            "yearIssued": "Premiere / Pub. year",
            "people": "Num. of performers (approx.)",
            "title": "Title",
        }
    },
    "privacy": {
        "about_privacy": {
            "_01": {
                "heading": "About collection and purpose of use of personal information",
                "par": "Personal information means information that can identify a specific individual. Personal information provided by users of this site will not be used for purposes other than those presented in advance, and will be handled appropriately in compliance with regulations of Waseda University and laws."
            },
            "_02": {
                "heading": "About provision to third parties",
                "par": "This site will not provide personal information to a third party without the consent of the person. However, we may provide information on exceptions stipulated by law without the consent of the individual."
            },
            "_03": {
                "heading": "Using Google Analytics",
                "list01": "Google's use of information collected from sites and apps that use Google's services",
                "list01_url": "https://policies.google.com/technologies/partner-sites",
                "list02": "Google privacy policy",
                "list02_url": "https://policies.google.com/privacy",
                "par": "This site uses Google Analytics to understand the usage status and to improve the operation of the site. Google Analytics uses cookies to collect data. This data is collected anonymously and is not personally identifiable. You can also refuse collection by disabling cookies in your browser settings. The data collected is managed in accordance with Google's privacy policy. Please check the Google Analytics website for the terms of use and privacy policy of Google Analytics."
            },
            "_04": {
                "heading": "Enquiries (Privacy Policy)",
            },
        },
        "enquiries": {
            "address": "1-6-1 Nishiwaseda, Shinjuku-ku, Tokyo 169-8050 Japan",
            "company": "The Tsubouchi Memorial Theatre Museum, Waseda University",
            "email": "E-MAIL: enpaku.jdta (at) gmail.com  * Please convert (at) to @",
            "fax": "FAX: 03-5273-4398",
            "tel": "TEL: 03-5286-1829 (on weekdays 9:00 to 17:00)",
        },
        "lead": "Personal information will not be collected by browsing or using this site, but we may receive personal information from users for their purpose of inquiries. When operating this site, personal information provided by users will be handled appropriately under the following policy in consideration of privacy protection.",
        "title": {
            "about_privacy": "About personal information"
        }
    },
    "resultHeader": {
        "checkbox": {
            "archive": "Excerpt Video available",
            "award": "Award",
        },
        "narrowDown": "Narrow down：",
        "number": " Results",
        "genre": "Category",
        "order": "Sort order:",
        "order_ascending": ", ascending",
        "order_descending": ", decending",
        "select": {
            "imageType": "Image type",
            "flyer": "flyer",
            "stage": "stage photo",
            "poster": "other",
        },
        "title": {
            "search": "All Performances",
            "result": "Search Results",
            "resultImages": "Results(Still image)",
            "resultVideos": "Results(Video)",
        },
    },
    "searchHeader": {
        "reset": "Reset Conditions",
        "reset_all": "Reset All Conditions",
        "detail": "Advanced Setting",
        "detail_addWords": "Additional word",
        "detail_addWords_include": "Incl.",
        "detail_addWords_notInclude": "Not incl.",
        "detail_category": "Category",
        "detail_category_dance": "Dance",
        "detail_category_drama": "Contemporary theatre",
        "detail_category_tradition": "Traditional performing arts",
        "detail_genre": "(Please select)",
        "detail_input_placeholder": "Keyword",
        "detail_other": "Other",
        "detail_other_award": "Award",
        "detail_other_video": "Excerpt Video available",
        "detail_year": "Year of performance",
        "input_placeholder": "Search",
        "input_submit": "Search",
        "range_mark": "-",
        "target": "Search target：",
        "target_image": "Still image",
        "target_performance": "Performance",
        "target_video": "Video",

        "awg_company": "Company/Producer",
        "awg_company_t": "Company/Producer transcription",
        "awg_title": "Performance",
        "awg_title_t": "Performance transcription",
        "awg_performanceTitle": "Programme title",
        "awg_performanceTitle_t": "Programme title transcription",
        "awg_sponsor": "Organizer",
        "awg_theater": "Venue",
        "awg_theater_t": "Venue transcription",
        "awg_keyword": "keyword",
        "awg_staff": "Staff (name)",
        "awg_staff_t": "Staff (name transcription)",
        "awg_staff_role": "Staff (role)",
        "awg_actor": "Cast (name)",
        "awg_actor_t": "Cast (name transcription)",
        "awg_actor_character": "Cast (role)",
    },
    "search_keyword": "Search from keywords",
    "tagCloud": {
        "title": "Examples",
    },
    "terms": {
        "about": {
            "caption01": "*Productions that can be viewed are subject to change without notice.",
            "list01": "This JDTA website is provided to search information related to the video recordings of the Japanese performing arts in three domains, spanning contemporary theatre, dance and traditional performing arts. JDTA is developed and operated by the Theatre Museum of Waseda University, as a part of Emergency Performing arts Archive + Digital theatre(EPAD). EPAD is one of the Agency for Cultural Affairs' 2021 Projects to Strengthen the Earning Capacity of the Cultural Arts.",
            "list02": "As for productions for which rights processing for the distribution of stage recordings has been completed by the EPAD project, the excerpted videos of 3 minutes are available for viewing on this site. Please check the theater company website etc. to see if it is fully distributed.",
            "list03": "Although the entire performance video cannot be viewed on this site, the stage works and plays posted on this site that have been approved by the content provider can be viewed, and viewed at the Theatre Museum by prior reservation. Please check the website of the Theatre Museum for how to use it.",
            "list04": "Information and keywords related to stage performance videos and playtexts on this site are posted based on those provided by the content providers. Therefore, please note that there may be inconsistencies in the notation and the amount of information.",
        },
        "copyright": {
            "list01": "The copyright for this site and its databases belongs to the Theatre Museum.",
            "list02": "Any image/video data, performance/piece précis, and company/entertainment entity description that appears on this site is posted with the permission of the content provider, with the copyright belonging to the author(s) of each work. Visitors to this site may not copy or reprint anything that appears on the site without permission, with some exceptions permitted by law including private use.",
            "list03": "With the exception of performance/piece précis and company/entertainment entity descriptions, textual information that appears on this site (metadata relating to performances and materials) may be used in accordance with the following Creative Commons license.",
            "url": "https://creativecommons.org/publicdomain/zero/1.0/",
        },
        "disclaimer": "The information found on this site is produced by based on information provided by contributors. The Theatre Museum accepts no responsibility for any actions taken by the site’s visitors using the information on this site. In addition, please note that the site’s contents, terms of use, and URL (address) may be altered or removed without prior notice. Thank you for your understanding.",
        "enquiries": {
            "company": "The Tsubouchi Memorial Theatre Museum, Waseda University",
            "email": "Email: enpaku.jdta (at) gmail.com (Note: Please change “at” to “@”)",
            "fax": "Fax: 03-5273-4398",
            "tel": "Tel: 03-5286-1829 (09.00-17.00 JST on weekdays)",
        },
        "link": {
            "list01": "Please feel free to link to this site. However, links may not be made if any of the following conditions apply, or if there is a risk of the following conditions applying.",
            "list01_01": "The website from which the link is made includes content that slanders or intends to cause loss of credibility to this website, other companies (or people), or other organisations.",
            "list01_02": "The website from which the link is made infringes or risks infringing the intellectual property rights including copyrights and trademarks of this website or any other company (person) or organisation, as well as other rights including property, privacy, or portrait rights.",
            "list01_03": "The website from which the link is made violates public order or decrees including laws, ordinances, and regulations, and/or if there is a risk of it interfering with the running of this site.",
            "list02": "Please note that the URL (address) and contents of this site are subject to change without prior notice.",
            "list03": "When linking, please specify that the corresponding page is part of this site.",
        },
        "systemRequirements": {
            "lead": "We recommend using the following browsers when visiting this site for a safer and more pleasant user experience.",
            "mac": "For Mac users",
            "mac_chrome": "Google Chrome, latest version",
            "mac_firefox": "Mozilla Firefox, latest version",
            "mac_safari": "Safari 5.0 or later",
            "windows": "For Windows users",
            "windows_chrome": "Google Chrome, latest version",
            "windows_edge": "Microsoft Edge, latest version",
            "windows_firefox": "Mozilla Firefox, latest version",
            "windows_ie": "Microsoft Internet Explorer 11",
        },
        "title": {
            "about": "About this site",
            "copyright": "Site copyright",
            "link": "Linking to this site",
            "disclaimer": "Disclaimer",
            "systemRequirements": "System Requirements",
            "enquiries": "Enquiries",
        },
    },
};