
import { defineComponent, onMounted, ref } from "vue";
const logo = require("@/templates/script/logo");

export default defineComponent({
  name: "MenuPostsLogoMark",
  setup() {
    const logoElement = ref<HTMLElement | undefined>();
    onMounted(() => {
      if (logoElement.value) {
        logo.activateMenuPostsLogo(logoElement.value);
      }
    });
    return { logoElement };
  },
});
