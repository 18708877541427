
import { defineComponent } from "vue";
import ExternalLinks from "@/templates/component/ExternalLinks.vue";
import { dictionary } from "@/compositions/pageComposition";
import { epkhref, epksrc, goThere } from "@/helper";

export default defineComponent({
  name: "Footer",
  components: { ExternalLinks },
  setup() {
    return {
      lang: dictionary,
      epkhref,
      epksrc,
      goThere,
    };
  },
});
