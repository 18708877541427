import axios from 'axios';

export const apiGet = async <T = any>(url: string, params: any = {}): Promise<{ headers: any; data: T }> => {
    const axiosResponse = await axios({
        method: 'get',
        url,
        params,
        responseType: 'json',
    });

    if (axiosResponse.status !== 200) {
        throw new Error(
            'サーバーとの接続に失敗しました ' +
            axiosResponse.status.toString() +
            ': ' +
            axiosResponse.statusText
        );
    }

    const { headers, data } = axiosResponse;
    return { headers, data };
};
