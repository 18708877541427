import { createApp, Component } from 'vue';

import { dictionary, setGlobalError, setGlobalLoader, setLanguage } from "@/compositions/pageComposition";
import { fetchRow } from "@/compositions/theatreDetailComposition";
import { fetchRecordset as fetchAnnouncementRecordset } from "@/compositions/announcementListComposition";
import { fetchRecordset as fetchPlaytextRecordset } from "@/compositions/playtextListComposition";
import listState, { retrieveState, searchCondition } from "@/compositions/theatreListComposition";
import { loadKeywords } from './compositions/keywordsComposition';

export default async function bootstrap(Component: Component) {

    if (!window.location.pathname.match(/^(\/en)?(\/.*?|)$/)) {
        throw new Error('runtime error');
    }

    // 言語取得
    const lang = RegExp.$1.replace('/', '');

    // 最後のスラッシュ削除はomeka仕様
    const pathname = RegExp.$2.replace(/\/$/, '');

    // 言語切替
    await setLanguage(lang === 'en' ? 'en_US' : 'ja_JP');

    // TOP ページは特殊
    if(pathname === '') {
        fetchAnnouncementRecordset();
        loadKeywords();
        createApp(Component).mount('#app');
        return;
    }

    // ローダー
    setGlobalLoader(true);

    // ページ表示(ローディング表示)
    createApp(Component).mount('#app');

    const wait: Promise<any>[] = [];

    // 共通コンテンツの読込(キーワード)
    // - メニュー用の画像は別で読込まれる(loadThumbnails())
    const loadingKeywords = loadKeywords();

    // 各ページのコンテンツ読み込み
    switch (true) {
        // 公演検索
        case !!pathname.match(/\/search\b/): {
            // const hash = window.location.hash;
            // fixSearchPage(hash);
            await retrieveState();
            // wait.push(retrieveState());
            wait.push(loadingKeywords);
            break;
        }
        // 公演詳細
        case !!pathname.match(/\/detail\/(.*)$/): {
            const identifier = RegExp.$1;
            if (identifier) {
                try {
                    await fetchRow({ id: identifier })
                } catch(e) {
                    // const message = dictionary.value.message.not_found;
                    setGlobalError(404, 'Not found');
                }
            } else {
                setGlobalError(404, 'Not found');
            }
            break;
        }
        // 戯曲目録
        case !!pathname.match(/\/playtext\b$/): {
            wait.push(fetchPlaytextRecordset());
            break;
        }
        // ニュース
        case !!pathname.match(/\/news(?:\/(\d+))?$/): {
            const id = RegExp.$1 as any;
            wait.push(fetchAnnouncementRecordset({ id }));
            break;
        }
    }

    await Promise.all(wait);
    setGlobalLoader(false);
}

function fixSearchPage(hash: string = '') {
    switch (hash) {
        case "#image":
            searchCondition.searchType = "image";
            listState.resultType = "image";
            break;
        case "#video":
            searchCondition.searchType = "video";
            listState.resultType = "video";
            break;
        default:
            searchCondition.searchType = "overview";
            listState.resultType = "overview";
    }
}