
import { defineComponent, onBeforeUnmount, onMounted, ref } from "vue";
import lottie, { AnimationItem } from "lottie-web";
import spinner from "@/templates/json/spinner.json";

export default defineComponent({
  name: "Loader",
  setup() {
    const lottieContainer = ref<HTMLDivElement | undefined>();
    const lottieRef = ref<AnimationItem | undefined>();
    onMounted(() => {
      if (lottieContainer.value) {
        lottieRef.value = lottie.loadAnimation({
          animationData: spinner,
          autoplay: true,
          container: lottieContainer.value,
          loop: true,
          renderer: "svg",
        });
      }
    });
    onBeforeUnmount(() => {
      if (lottieRef.value) {
        lottieRef.value.destroy();
      }
    });
    return { lottieContainer };
  },
});
