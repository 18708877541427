import { site } from '@/util/config';
import { apiGet } from './apiGet';
import { FetchTheatricalResource } from './interface';

const endpoint = site.apiEndpoints.theatricalResources;

export const fetchTheatricalResource: FetchTheatricalResource = async ({ lang = 'ja', id }) => {
    // return mockData;
    const { data } = await apiGet(endpoint , { id, lang });
    return data;
};
