module.exports = {
    body: '#body',
    // wrapper: '#wrapper', // 未使用
    header: '#header',
    // main: '#js-main', // 未使用
    // footer: '#js-footer', // 未使用
    menuPosts: '#js-menuPosts',
    keywords: '#js-keywords a',
    menuPostItem: '.js-itemKeyword',
    menuToggleKeyword: '.js-toggleKeyword',
    menuPostsBtn: '.js-menuPostsBtn',
    menuBtn: '.js-menuBtn',
    // splash
    splash: '#js-splash',
    splashWidthId: '#js-menu',
    splashLogoLgId: '#js-menuLogo',
    splashLogoSmId: '#js-logoMark',
};