const site = require('@/config/site.js');

require('lazysizes');

// detail (jquery plugin "multilineAccordion")
require('./multilineAccordion');

// search | result
const stickifyListHeader = require('./intersectionObserver');

const {
  activateHeaderLogo,
  activateMenuLogoMark,
  activateMenuPostsLogo
} = require('./logo');

// all pages
const setupLayoutInitializer = require('./menu');

// search | result
const attachSelectWithFix = require('./select');

// all
const attachWindowControlAndAnchorScroll = require('./etc');

// search | result
require('./search');

function script(adapter, option = {}) {
  const { initializeTopPage, initializeSubPage } = setupLayoutInitializer(adapter);

  attachWindowControlAndAnchorScroll();
  switch (option.layoutType) {
    case 'top':
      initializeTopPage({
        disableSplash: site.disableSplash
      });
      break;
    case 'list':
      initializeSubPage();
      stickifyListHeader();
      attachSelectWithFix();
      break;
    default:
      initializeSubPage();
  }
}
script.multilineAccordion = function (jsMultilineAccordionElement) {
  $(jsMultilineAccordionElement).multilineAccordion();
}
script.headerLogo = activateHeaderLogo;
script.menuLogoMark = activateMenuLogoMark;
script.menuPostsLogo = activateMenuPostsLogo;

module.exports = script;
