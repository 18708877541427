
import { epkhref, epksrc, goThere } from "@/helper";
import ExternalLinks from "@/templates/component/ExternalLinks.vue";
import HomeMenuLogo from "@/templates/component/HomeMenuLogo.vue";
import MenuKeywords from "@/templates/component/MenuKeywords.vue";
import MenuLogoMark from "@/templates/component/MenuLogoMark.vue";
import MenuPosts from "@/templates/component/MenuPosts.vue";
import { defineComponent, onMounted, reactive } from "vue";
import { keywords } from "@/compositions/keywordsComposition";
import { latestNews } from "@/compositions/announcementListComposition";
import {
  dictionary,
  setLanguage,
  language,
} from "@/compositions/pageComposition";
import { site } from "@/util/config";

export default defineComponent({
  name: "Menu",
  components: {
    ExternalLinks,
    MenuPosts,
    MenuLogoMark,
    HomeMenuLogo,
    MenuKeywords,
  },
  props: {
    asHome: Boolean,
    homeMenuLogo: Boolean,
    splash: Boolean,
  },
  setup() {
    // Note: with "ready", fix unexpected menu fadeout when the page loaded problem.
    const data = reactive({ ready: false });
    onMounted(() => {
      data.ready = true;
    });

    return {
      data,
      menuKeywords: keywords,
      latestNews,
      setLanguage,
      language,
      lang: dictionary,
      siteName: site.siteName,
      epkhref,
      epksrc,
      goThere,
    };
  },
});
