import { computed, reactive, ref } from 'vue';
import { TheatricalResource } from '@/enpaku';
import { language } from '@/compositions/pageComposition';
import * as fontplus from '@/fontplus';
import { fetchTheatricalResource } from '@/api/fetchTheatricalResource';
import { FetchTheatricalResourceParam } from '@/api/interface';

interface TheatreItemState {
    loadingRequestId: number;
    nextRequestId: number;
    row: null | TheatricalResource;
}

const state = reactive<TheatreItemState>({
    loadingRequestId: -1,
    nextRequestId: 1,
    row: null,
});

export const loading = computed<boolean>(() => -1 !== state.loadingRequestId);
export const loaded = computed<boolean>(() => !!state.row && !loading.value);

export const theatre = computed<null | TheatricalResource>(
    () => state.row ? state.row : null
);

type Unregisterer = () => void;
const fontplusUnregisterRef = ref<Unregisterer>();
export const fetchRow = async (param: FetchTheatricalResourceParam) => {
    const { id } = param;
    const lang = language.value === 'en_US' ? 'en' : 'ja';

    state.loadingRequestId = state.nextRequestId;
    state.nextRequestId = state.nextRequestId + 1;

    const requestId = state.loadingRequestId;

    try {
        const row = await fetchTheatricalResource({ id, lang });

        if (requestId === state.loadingRequestId) {
            state.loadingRequestId = -1;
            state.row = row;
        }

        fontplusUnregisterRef.value && fontplusUnregisterRef.value();
        fontplusUnregisterRef.value = fontplus.registerText(JSON.stringify(row));
        await fontplus.loadFontplus('theatreListComposition');

    } catch (error) {
        state.loadingRequestId = -1;
        throw error;
    }
};
