/**
 * @see https://photoswipe.com/documentation/options.html
 */

import PhotoSwipe from "photoswipe";

const PhotoSwipeUI_Default = require("photoswipe/dist/photoswipe-ui-default");

export const defaultOptions = <T = any>(pswp: PhotoSwipe<T>) => ({
  // Size of top & bottom bars in pixels,
  // "bottom" parameter can be 'auto' (will calculate height of caption)
  // option applies only when mouse is used, 
  // or width of screen is more than 1200px
  // 
  // (Also refer to `parseVerticalMargin` event)
  barsSize: {
    top: 44 as 'auto' | number,
    bottom: 'auto' as 'auto' | number
  },

  // Adds class pswp__ui--idle to pswp__ui element when mouse isn't moving for 4000ms
  timeToIdle: 4000,

  // Same as above, but this timer applies when mouse leaves the window
  timeToIdleOutside: 1000,

  // Delay until loading indicator is displayed
  loadingIndicatorDelay: 1000,

  // Function builds caption markup
  addCaptionHTMLFn: function (item: any, captionEl: HTMLElement, isFake: boolean) {
    // item      - slide object
    // captionEl - caption DOM element
    // isFake    - true when content is added to fake caption container
    //             (used to get size of next or previous caption)

    if (!item.title) {
      captionEl.children[0].innerHTML = '';
      return false;
    }
    captionEl.children[0].innerHTML = item.title;
    return true;
  },

  // Buttons/elements
  closeEl: true,
  captionEl: true,
  fullscreenEl: true,
  zoomEl: true,
  shareEl: true,
  counterEl: true,
  arrowEl: true,
  preloaderEl: true,

  // Tap on sliding area should close gallery
  tapToClose: false,

  // Tap should toggle visibility of controls
  tapToToggleControls: true,

  // Mouse click on image should close the gallery,
  // only when image is smaller than size of the viewport
  clickToCloseNonZoomable: true,

  // Element classes click on which should close the PhotoSwipe.
  // In HTML markup, class should always start with "pswp__", e.g.: "pswp__item", "pswp__caption".
  // 
  // "pswp__ui--over-close" class will be added to root element of UI when mouse is over one of these elements
  // By default it's used to highlight the close button.
  closeElClasses: ['item', 'caption', 'zoom-wrap', 'ui', 'top-bar'],

  // Separator for "1 of X" counter
  indexIndicatorSep: ' / ',



  // Share buttons
  // 
  // Available variables for URL:
  // {{url}}             - url to current page
  // {{text}}            - title
  // {{image_url}}       - encoded image url
  // {{raw_image_url}}   - raw image url
  shareButtons: [
    { id: 'facebook', label: 'Share on Facebook', url: 'https://www.facebook.com/sharer/sharer.php?u={{url}}' },
    { id: 'twitter', label: 'Tweet', url: 'https://twitter.com/intent/tweet?text={{text}}&url={{url}}' },
    { id: 'pinterest', label: 'Pin it', url: 'http://www.pinterest.com/pin/create/button/?url={{url}}&media={{image_url}}&description={{text}}' },
    { id: 'download', label: 'Download image', url: '{{raw_image_url}}', download: true }
  ],


  // Next 3 functions return data for share links
  // 
  // functions are triggered after click on button that opens share modal,
  // which means that data should be about current (active) slide
  getImageURLForShare: function (shareButtonData: any[]) {
    // `shareButtonData` - object from shareButtons array
    // 
    // `pswp` is the gallery instance object,
    // you should define it by yourself
    // 
    return pswp.currItem.src || '';
  },
  getPageURLForShare: function (shareButtonData: any[]) {
    return window.location.href;
  },
  getTextForShare: function (shareButtonData: any[]) {
    return 'getTextForShare';
    // return pswp.currItem.title || '';
  },

  // Parse output of share links
  parseShareButtonOut: function (shareButtonData: any[], shareButtonOut: string) {
    // `shareButtonData` - object from shareButtons array
    // `shareButtonOut` - raw string of share link element
    return shareButtonOut;
  }
});
export type PhotoSwipeUIDefaultOptions = ReturnType<typeof defaultOptions>;
export default PhotoSwipeUI_Default;
