
const delay = 300;
const listeners = [];
let resizeRequestCount = 0;

window.addEventListener('resize', function (e) {
  const resizeId = ++resizeRequestCount;
  setTimeout(() => {
    if (resizeRequestCount === resizeId) {
      listeners.forEach((fn) => fn(e));
    }
  }, delay);
});

const addCompletedListener = (fn) => listeners.push(fn);

// [example]
// addCompletedListener((e)=>{
//   console.log(e);
//   console.log('trigger something');
// });

const mediaChecks = {
  // swiper
  useMdSwiper: window.matchMedia('only screen and (max-width:750px)'),
  // etc scrollAnchor
  useMdOffset: window.matchMedia('only screen and (max-width:767px)'),
  // multilineAccordion
  useMdLines: window.matchMedia('only screen and (max-width:750px)'),
}

module.exports = {
  onResized: addCompletedListener,
  is: (checkName) => checkName in mediaChecks
    ? mediaChecks[checkName].matches
    : false
};
