
import { defineComponent } from "vue";
import { epkhref, epksrc, goThere } from "@/helper";

import HeaderLogo from "@/templates/layout/HeaderLogo.vue";
import { site } from "@/util/config";
import {
  dictionary,
  setLanguage,
  language,
} from "@/compositions/pageComposition";

export default defineComponent({
  name: "Header",
  components: { HeaderLogo },
  setup() {
    return {
      siteName: site.siteName,
      epkhref,
      epksrc,
      goThere,
      setLanguage,
      language,
    };
  },
});
