import PhotoSwipe from "photoswipe";
import PhotoSwipeUIDefault, {
  PhotoSwipeUIDefaultOptions,
} from "./PhotoSwipeDefaultUI";
import { language } from "@/compositions/pageComposition";
import { TheatricalResource, StillImageItem } from "@/enpaku";

const getGalleryElement = (): HTMLElement => {
  const elem = document.getElementById("js-photo-swipe-gallery");
  if (!elem) {
    throw new Error('#js-photo-swipe-gallery is not found');
  }
  return elem;
};

const photoSwipeDefaultOptions: PhotoSwipe.Options &
  Partial<PhotoSwipeUIDefaultOptions> = {
  galleryUID: 1,
  showHideOpacity: true,
  index: 0,
  // PhotoSwipeDefaultUI.Options
  counterEl: false,
  barsSize: {
    top: 24,
    bottom: 88,
  },
  shareEl: false,
};

type Caption = {
  type: string;
  identifier: string;
  mediaType: string;
  title: string;
  rights: string;
  applicantAgent: string;
  link?: string;
};

export type GalleryItem = PhotoSwipe.Item & {
  title?: string;
  caption?: Caption;
};

type CaptionFn = (item: GalleryItem) => string;

const createCaptionForStillImageOnDetail = (caption: Caption) => {
  const { identifier = "", mediaType = "", rights = "" } = caption;
  const id = identifier ? '/ ID:' + identifier : '';
  return `
    <div class="c-detail__image_caption">
      <p class="c-detail__image_credit">
        <span class="c-detail__image_rights">${htmlspecialchars(rights) || '&nbsp;'}</span>
        ${htmlspecialchars(id) || '&nbsp;'}
      </p>
      <p class="c-detail__image_type">${htmlspecialchars(mediaType) || '&nbsp;'}</p>
    </div>`;
}

const createCaptionForStillImageList = (caption: Caption) => {
  const { identifier = "", mediaType = "", title = "", link = "", applicantAgent = "" } = caption;
  return `
    <dl class="c-item_image__content">
      <dt class="c-item_image__label">公演名</dt>
      <dd class="c-item_image__title">
        <a class="js-pswpTitle" href="${link}">
          ${htmlspecialchars(title)}
        </a>
      </dd>
      <dt class="c-item_image__label">上演主体</dt>
      <dd class="c-item_image__company">
        ${applicantAgent ? htmlspecialchars(applicantAgent) : '&nbsp;'}
      </dd>
      <dt class="c-item_image__label">ID</dt>
      <dd class="c-item_image__id">
        ${identifier ? 'ID: ' + htmlspecialchars(identifier) : '&nbsp;'}
      </dd>
      <dt class="c-item_image__label">画像の種類</dt>
      <dd class="c-item_image__type">${htmlspecialchars(mediaType)}</dd>
    </dl>
    <a class="c-item_image__detail" href="${link}"></a>`;
}

const captionFn: CaptionFn = (item) => {
  const { caption = { type: null } } = item;
  switch (caption.type) {
    case 'listItem':
      return createCaptionForStillImageList(caption);
    case 'detailItem':
      return createCaptionForStillImageOnDetail(caption);
    default:
      return item.title || "no title";
  }
};
function htmlspecialchars(str: string): string {
  return (str + "")
    .replace(/&/g, "&amp;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
}

export const convertStillImageItemsToGalleryItems = (
  stillImages: StillImageItem[] = [],
  captionType = 'listItem'
): GalleryItem[] => {
  const lang = language.value === 'en_US' ? 'en' : 'ja';
  const createLink = (identifier: string): string => {
    const paths = ['detail'];
    if (lang === 'en') {
      paths.unshift('en');
    }
    paths.push(identifier);
    return '/' + paths.join('/');
  };

  const galleryItems = stillImages.reduce(
    (collection, stillImage) => {
      const item: GalleryItem = {
        src: stillImage.StillImage$primary_media_url,
        w: 2048,
        h: 1280,
        msrc: stillImage.StillImage$thumbnail_display_urls.large ?? undefined,
        title: [
          stillImage.TheatricalResource$dcterms__title,
          stillImage.StillImage$dcepk__materialType,
        ].join(" "),
        caption: {
          type: captionType,
          identifier: stillImage.StillImage$dcepk__sourceIdentifier || '',
          mediaType: stillImage.StillImage$dcepk__materialType || '',
          title: stillImage.TheatricalResource$dcterms__title || '',
          rights: stillImage.StillImage$dcterms__rights || '',
          link: createLink(stillImage.TheatricalResource$dcterms__identifier),
          applicantAgent: stillImage.TheatricalResource$dcepk__applicantAgent.map(
            (a) => a.applicantAgent$foaf__name
          ).join(" / ")
        },
      };
      return [...collection, item];
    },
    [] as GalleryItem[]
  );
  return galleryItems;
};

export const photoSwipeStillImageGalleryItems = (
  theatreListRecordset: any = [],
  captionType: 'listItem' | 'detailItem'
) /*: GalleryItem[]*/ => {

  const lang = language.value === 'en_US' ? 'en' : 'ja';
  const createLink = (theatre: TheatricalResource): string => {
    const paths = ['detail'];
    if (lang === 'en') {
      paths.unshift('en');
    }
    paths.push(theatre.TheatricalResource$dcterms__identifier);
    return '/' + paths.join('/');
  };

  const galleryItems: GalleryItem[] = theatreListRecordset.reduce(
    (collection: GalleryItem[], theatre: TheatricalResource) => {
      return theatre.TheatricalResource$dcepk__relatedStillImage.reduce(
        (collection: GalleryItem[], stillImage) => {
          const item: GalleryItem = {
            src: stillImage.StillImage$primary_media_url,
            w: 2048,
            h: 1280,
            msrc: stillImage.StillImage$thumbnail_display_urls.large ?? undefined,
            title: [
              theatre.TheatricalResource$dcterms__title,
              stillImage.StillImage$dcepk__materialType,
            ].join(" "),
            caption: {
              type: captionType,
              identifier: stillImage.StillImage$dcepk__sourceIdentifier || '',
              mediaType: stillImage.StillImage$dcepk__materialType || '',
              title: theatre.TheatricalResource$dcterms__title || '',
              rights: stillImage.StillImage$dcterms__rights || '',
              link: createLink(theatre),
              applicantAgent: theatre.TheatricalResource$dcepk__applicantAgent.map(
                (a) => a.applicantAgent$foaf__name
              ).join(" / ")
            },
          };

          return [...collection, item];
        },
        collection
      );
    },
    []
  );
  return galleryItems;
};

const openPhotoSwipeGallery = (items: GalleryItem[], index: number) => {
  if (items.length === 0) return null;

  // photoswipe が破壊的なためコピーを使用する
  const galleryElement = getGalleryElement();
  const galleryItems = items.map((i: GalleryItem) => ({ ...i }));
  const gallery = new PhotoSwipe(
    galleryElement,
    PhotoSwipeUIDefault,
    galleryItems,
    {
      ...photoSwipeDefaultOptions,
      index,
      addCaptionHTMLFn: (item: any, captionEl: any, isFake: any) => {
        captionEl.children[0].innerHTML = captionFn(item);
        return true;
      },
    }
  );
  gallery.init();
  return gallery;
};

export default openPhotoSwipeGallery;
